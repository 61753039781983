import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  DigiNxtSeries,
  DiplomaProgramme,
  MasterMindSeries,
  ShortTermCareerProgramme,
} from "../../assets/Data/Courses";
import {
  Fieldset,
  Form,
  FormDetail,
  FormSuccess,
  FormSuccessHeading,
  FormSuccessPara,
  FormTitle,
  GoBack,
  Input,
  Instruction,
  Label,
  Select,
  Textarea,
} from "./EnrolmentForm.styles";

const EnrolmentForm = ({ id }) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sex, setSex] = useState("");
  const [programme, setProgramme] = useState(id);
  const [employment, setEmployment] = useState("");
  const [aboutus, setAboutus] = useState("");
  const [tellus, setTellus] = useState("");
  const toSheet = useRef();
  const [success, setSuccess] = useState("");

  const handleSubmit = (e) => {
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbysC2wv6L3jXNvWclzIF-mcWM-LcliizP0nBfUH29ODEgF7jZ2u8W2b4gxgH3LL--A/exec";

    e.preventDefault();
    fetch(scriptURL, { method: "POST", body: new FormData(toSheet.current) })
      .then((response) => {
        clearForm();
        setSuccess("success");
        console.log("Success!", response);
      })
      .catch((error) => {
        console.error("Error!", error.message);
        setSuccess("error");
      });
  };

  const clearForm = () => {
    setFirstname("");
    setLastname("");
    setEmail("");
    setPhone("");
    setSex("");
    setProgramme("");
    setEmployment("");
    setAboutus("");
    setTellus("");
  };
  return (
    <>
      <Form
        $mode={success}
        ref={toSheet}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Instruction>
          <FormTitle>Enrolment Form</FormTitle>
          <FormDetail>
            Please provide the neccesary information to the required fields
            below.
          </FormDetail>
        </Instruction>
        <Fieldset>
          <Label htmlFor="firstname">First Name</Label>
          <Input
            type="text"
            id="firstname"
            name="Firstname"
            placeholder="John"
            value={firstname}
            onChange={(e) => {
              setFirstname(e.target.value);
            }}
          />
        </Fieldset>
        <Fieldset>
          <Label htmlFor="lastname">Last Name</Label>
          <Input
            type="text"
            id="lastname"
            name="Lastname"
            placeholder="Doe"
            value={lastname}
            onChange={(e) => {
              setLastname(e.target.value);
            }}
          />
        </Fieldset>
        <Fieldset>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            id="email"
            name="Email"
            placeholder="someone@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Fieldset>
        <Fieldset>
          <Label htmlFor="phone">Phone No.</Label>
          <Input
            type="numeric"
            id="phone"
            name="Phone No."
            placeholder="+234 80 5555 7777 0r 0802 333 4444"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Fieldset>
        <Fieldset>
          <Label htmlFor="sex">Sex</Label>
          <Select
            id="sex"
            name="Sex"
            value={sex}
            onChange={(e) => setSex(e.target.value)}
          >
            <option value="" disabled>
              -- Select your sex --
            </option>
            <option value="male">Male</option>
            <option value="female">Femail</option>
            <option value="others">Others</option>
          </Select>
        </Fieldset>
        <Fieldset>
          <Label htmlFor="programme">Interested Programme</Label>
          <Select
            id="programme"
            name="Programme of Choice"
            value={programme}
            onChange={(e) => setProgramme(e.target.value)}
          >
            <option value="" disabled>
              -- Select A Programme --
            </option>
            <optgroup label="DigiNxt Series">
              {MasterMindSeries.map((course, i) => (
                <option key={i} value={course.title}>
                  {course.title}
                </option>
              ))}
            </optgroup>
            <optgroup label="Master Mind Series">
              {DigiNxtSeries.map((course, i) => (
                <option key={i} value={course.title}>
                  {course.title}
                </option>
              ))}
            </optgroup>
            <optgroup label="Employability Diploma Programme">
              {DiplomaProgramme.map((course, i) => (
                <option key={i} value={course.title}>
                  {course.title}
                </option>
              ))}
            </optgroup>
            <optgroup label="Career Enhancement Programme">
              {ShortTermCareerProgramme.map((course, i) => (
                <option key={i} value={course.title}>
                  {course.title}
                </option>
              ))}
            </optgroup>
          </Select>
        </Fieldset>
        <Fieldset>
          <Label htmlFor="employment">Employment Status</Label>
          <Select
            id="employment"
            name="Employment Status"
            value={employment}
            onChange={(e) => setEmployment(e.target.value)}
          >
            <option value="" selected disabled>
              -- Are You Employeed --
            </option>
            <option value="employeed-fulltime">Employeed Fulltime</option>
            <option value="employed-parttime">Employed Part-time</option>
            <option value="unemployeed">Unemployed</option>
            <option value="student">Student</option>
          </Select>
        </Fieldset>
        <Fieldset>
          <Label htmlFor="aboutus">How did you hear about us?</Label>
          <Select
            id="aboutus"
            name="How they heard about us"
            value={aboutus}
            onChange={(e) => setAboutus(e.target.value)}
          >
            <option value="" disabled>
              -- Select Medium --
            </option>
            <option value="linkedIn">LinkedIn</option>
            <option value="facebook">Facebook</option>
            <option value="our-student">Our Student</option>
            <option value="our-website">Our Website</option>
            <option value="google-search">Google Search</option>
            <option value="friend">Friend</option>
          </Select>
        </Fieldset>
        <Fieldset>
          <label htmlFor="tellus">
            Do you have anything to tell us (optional)
          </label>
          <Textarea
            name="Applicant Message"
            id="tellus"
            rows="10"
            placeholder="Type yor message..."
            value={tellus}
            onChange={(e) => setTellus(e.target.value)}
          ></Textarea>
        </Fieldset>
        <Input $mode="submit" type="submit" value="Submit" />
      </Form>
      <FormSuccess $mode={success}>
        <FormSuccessHeading>
          {success === "success" ? "Successfull" : "Error"}
        </FormSuccessHeading>
        <FormSuccessPara>
          {success === "success"
            ? "Your enrollment have been submited a representative will contact you shortly."
            : "An Error occured while submiting the form, try again later"}
        </FormSuccessPara>
        <Link to="/courses">
          <GoBack>Back</GoBack>
        </Link>
      </FormSuccess>
    </>
  );
};

export default EnrolmentForm;

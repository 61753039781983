import styled from "styled-components";
import { devices } from "../../assets/Data/Breakpoints";

export const ContactContainer = styled.section`
  display: flex;
  gap: 10%;
  max-width: 1000px;
  width: 100%;
  margin: 100px auto;

  @media ${devices.laptop} {
    gap: 3%;
  }

  @media ${devices.tablet} {
    gap: 10px;
  }
  @media ${devices.mobileL} {
    margin: 10px auto 50px auto;
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  padding: 20px;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${devices.mobileL} {
    paddind: 10px;
    gap: 30px;
  }
`;

export const RightContainer = styled.form`
  max-width: 400px;
  width: 100%;
  padding: 20px;
  background-color: #02044a;
  border-radius: 10px;
`;

export const Title = styled.h3`
  font-size: 1.6rem;
  margin-bottom: 10px;
`;
export const Para = styled.p`
  font-size: 1.1rem;
`;

export const ContactIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContactIcon = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  gap: 15px;
  color: #0000ee;

  &:hover {
    border: 1px solid blue;
    border-radius: 5px;
    width: 80%;
  }
`;

export const ContactMethod = styled.p`
  font-size: 1rem;
  color: #0000ee;
`;

export const Socials = styled.div`
  color: #0000ee;
  display: flex;
  gap: 20px;
  justify-content: center;
  font-size: 2rem;
`;

export const SLink = styled.a`
  padding: 5px 10px 2px 10px;
  border-radius: 100%;
  
  &:hover{
    background-color: blue;
    color: white;
    
  }
`;

export const Fieldset = styled.fieldset`
  border-radius: 5px;
  display: flex;
  color: #4c71d9;
  align-items: center;
  padding: 10px;
  font-size: 1.2rem;
  outline: none;
  border: 1px solid transparent;
  background-color: #002e6a;

  &:hover {
    border: 1px solid blue;
    border-radius: 5px;
  }
`;

export const Input = styled.input`
  font-size: 1.02rem;
  padding: 5px 10px;
  width: 100%;
  outline: none;
  border: none;
  background-color: unset;
  color: #ccc;

  &::placeholder {
    color: #4c71d9;
  }
`;

export const TextArea = styled.textarea`
  outline: none;
  font-size: 1.02rem;
  background-color: unset;
  color: #ccc;
  border: none;
  min-height: 100px;
  max-height: 200px;
  width: 100%;

  &::placeholder {
    color: #4c71d9;
  }
`;

export const Btn = styled.button`
  background-color: #0c75fe;
  color: white;
  display: block;
  margin: 20px 0 20px auto;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
`;

export const Label = styled.label`
  margin: 30px 0 5px 0;
  display: block;
  color: #ccc;
`;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AllCourses,
  DigiNxtSeries,
  DiplomaProgramme,
  MasterMindSeries,
  ShortTermCareerProgramme,
} from '../../assets/Data/Courses';
import CourseItem from '../../component/CourseItems/CourseItem.component';
import { ListContainer } from './CourseList.styles';
import { Fade } from 'react-awesome-reveal';

const CourseList = () => {
  const [category, setCategory] = useState([]);

  const { category_id } = useParams();

  useEffect(() => {
    getList(category_id);
  }, [category_id]);

  const getList = (path) => {
    switch (path) {
      case 'Master_Mind_Series':
        setCategory(MasterMindSeries);
        break;
      case 'DigiNxt_Series':
        setCategory(DigiNxtSeries);
        break;
      case 'Career_Enhancement_Programme':
        setCategory(ShortTermCareerProgramme);
        break;
      case 'Employability_Diploma_Programme':
        setCategory(DiplomaProgramme);
        break;
      default:
        setCategory(AllCourses);
    }
  };

  return (
    <ListContainer>
      {category.map((item, i) => (
        <Fade key={i} bottom>
          <CourseItem
            key={i}
            id={item.id}
            title={item.title}
            desc={item.desc}
            avi={item.avi}
            alt={item.alt}
            duration={item.duration}
          />
        </Fade>
      ))}
    </ListContainer>
  );
};

export default CourseList;

import styled from "styled-components";

export const Container = styled.article`
  max-width: 320px;
  width: 100%;
  padding: 20px 10px;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  position: relative;
`;

export const Figure = styled.figure`
  overflow: hidden;
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  border-radius: 10px;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;

export const Details = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const CourseTitle = styled.h2`
  font-size: 1rem;
`;

export const CourseDesc = styled.p`
  font-size: 0.8rem;
  margin: 5px 0 50px 0;
`;

export const Duration = styled.div`
  color: #999999;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

export const Apply = styled.button`
  padding: 15px 50px;
  border: none;
  border-radius: 5px;
  background-color: #0000ff;
  color: #ffffff;
  font-size: 1rem;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  &:hover {
    opacity: 80%;
  }
`;

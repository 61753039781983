import styled from "styled-components";

export const Container = styled.section`
  max-width: 600px;
  width: 100%;
  margin: 8% auto;
`;

export const Error = styled.div`
  width: 100%;
  font-family: poppins;
  font-size: 20px;
`;

export const Code = styled.h3`
  font-size: 8rem;
  font-weight: 700;
`;

export const Desc = styled.p`
  font-size: 2rem;
`;

export const Safty = styled.button`
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  background-color: #0000ff;
  color: #fff;
  font-size: 1.1rem;
`;

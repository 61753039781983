import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Category,
  CategoryName,
  CategorySummary,
} from "./CourseCategory.styles";

const CourseCategory = ({title, summary, route, course}) => {
  return (
    <Category>
      <CategoryName>{title}</CategoryName>
      <CategorySummary>
        {summary}
      </CategorySummary>
      <p>Courses includes: <span>{course} etc.</span></p>
      <Link to={`/category/${route}`} style={{alignSelf: "flex-end"}}>
        <Button>View Courses</Button>
      </Link>
    </Category>
  );
};

export default CourseCategory;

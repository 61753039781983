import React from "react";
import CarouselContainer from "../../container/Carousel/Carousel.component";
import Partner from "../../container/Partner/Partner.component";
import Testimonial from "../../container/Testimonial/Testimonial.component";
import Welcome from "../../container/Welcome/Welcome.component";
import WhyUs from "../../container/WhyUS/WhyUs.component";

const Home = () => {
  return (
    <>
      <CarouselContainer />
      <Welcome />
      <WhyUs />
      <Testimonial />
      <Partner />
    </>
  );
};

export default Home;

import ai from "../Images/couses-img/ai.jpg";
import android from "../Images/couses-img/android.jpg";
import bigdata from "../Images/couses-img/big-data.jpg";
import blockchain from "../Images/couses-img/blockchain.jpg";
import database from "../Images/couses-img/database.jpg";
import digitalmarketing from "../Images/couses-img/digital-marketing.jpg";
import digitalmarketing2 from "../Images/couses-img/digital-marketing2.jpg";
import dotnet from "../Images/couses-img/dotnet.jpg";
import hardware from "../Images/couses-img/hardware.jpg";
import infograph from "../Images/couses-img/infograph.jpg";
import infrastructuremgt from "../Images/couses-img/infrastructure-mgt.jpg";
import iot from "../Images/couses-img/iot.jpg";
import java from "../Images/couses-img/java.jpg";
import machinelearning from "../Images/couses-img/machine-lerning.jpg";
import microsoft from "../Images/couses-img/microsoft.jpg";
import pmp from "../Images/couses-img/pmp.jpg";
import security from "../Images/couses-img/security.jpg";
import software_eng from "../Images/couses-img/software-eng.jpg";
import web from "../Images/couses-img/web.jpg";
import web2 from "../Images/couses-img/web2.jpg";
import multimedia from "../Images/couses-img/multimedia.jpg";
import maintenance from "../Images/couses-img/maintainance.jpg";
import python from "../Images/couses-img/python.jpg";
import analytics from "../Images/couses-img/analytics.jpg";
import analytics2 from "../Images/couses-img/analytics2.jpg";
import desktop from "../Images/couses-img/desktop.jpg";

// categories
export const MasterMindSeries = [
  {
    id: "software_engineering",
    title: "Software Engineering",
    desc: "Intensive 1 - 2 years program aimed at creating Full Stack Developers, Builds specific skill set in Python, REST, Angular JS, React and DevOps.",
    avi: software_eng,
    alt: "software engineering illustrator",
    duration: "18 Months",
  },
  {
    id: "infrastructure_management",
    title: "Infrastructure Manangement",
    desc: "Intensive 1 - 2 program aimed at creating skills in infrastructure management, build strong foundational skill sin Hardware & Networking support, Windows Server Infrastructure, Database Adminstrator.",
    avi: infrastructuremgt,
    alt: "infrastructure management infrastructure",
    duration: "24 Months",
  },
  {
    id: "big_data",
    title: "Big Data",
    desc: "Big data is high volume, high velocity information to process to enable enhanced decision making. it consists of Hadoco, Zookeeper, House, Hive, Storm Distributed Live Computing and Sqoop.",
    avi: bigdata,
    alt: "big data illustration",
    duration: "4 Months",
  },
  {
    id: "java_devops",
    title: "Enterprise Java with DevOps",
    desc: "Producing software for multiple platforms using JAVA with DevOps model, you are bringing together your development and your operation team, and their work is no longer in silos.",
    avi: java,
    alt: "java illustration",
    duration: "6 Months",
  },
];

export const DigiNxtSeries = [
  {
    id: "digital-marketing-1",
    title: "Professional Diploma in Digital Marketing I",
    desc: "It helps learners to understand how to plan, implement and manage a comprehensive digital marketing strategy in Search Engine Optimization (SEO), Search Engine Monetization (SEM), Mobile and Email Marketing, ORM.",
    avi: digitalmarketing,
    alt: "digital marketing illustrator",
    duration: "4 Months",
  },
  {
    id: "digital-marketing-2",
    title: "Professional Diploma in Digital Marketing II",
    desc: "It helps learners to understand how to plan, implement and manage a comprehensive digital marketing strategy in Search Engine Optimization (SEO), Search Engine Monetization (SEM), Mobile and Email Marketing, ORM.",
    avi: digitalmarketing2,
    alt: "digital marketing illustrator",
    duration: "4 Months",
  },
  {
    id: "data-analytics-2",
    title: "Professional Diploma in Data Analytics II",
    desc: "Data Analytics course is designed to make one proficient in Data Analytics and techniques starting from the fundamentals of statics going onto advanced use of tools like R and Python, Artificial Intelligence, Business Intelligence.",
    avi: analytics,
    alt: "data analytics illustrator",
    duration: "4 Months",
  },
  {
    id: "data-analytics-3",
    title: "Professional Diploma in Data Analytics III",
    desc: "Data Analytics course is designed to make one proficient in Data Analytics and techniques starting from the fundamentals of statics going onto advanced use of tools like R and Python, Artificial Intelligence, Business Intelligence.",
    avi: analytics2,
    alt: "data analytics illustrator",
    duration: "4 Months",
  },
];

export const DiplomaProgramme = [
  {
    id: "dotnet",
    title: "Diploma in .Net Technologies",
    desc: "Aims to teach progamming and thereafter, design and develop web based applications on cutting edge .NET technology",
    avi: dotnet,
    alt: "dot net illustrator",
    duration: "4 Months",
  },
  {
    id: "java",
    title: "Diploma in Java Technologies",
    desc: "The program will equipt you with skills for designing, implementing, and maintaining Java-based software and applications, contributing to all stages of the software development lifecycle. You will thoroughly analyze user requirements, envision system features, and define application functionality.",
    avi: java,
    alt: "java illustrator",
    duration: "4 Months",
  },
  {
    id: "web-development",
    title: "Diploma in Web Development",
    desc: "Skills in creating a web based dynamic application using PHP and MySQL",
    avi: web2,
    alt: "web development illustrator",
    duration: "4 Months",
  },
  {
    id: "digital-marketing",
    title: "Diploma in Digital Marketing",
    desc: "It helps learners to understand how to plan, implement and manage a comprehensive digital marketing strategy in Search Engine Optimization (SEO), Search Engine Monetization (SEM), Mobile and Email Marketing, ORM.",
    avi: digitalmarketing,
    alt: "digital marketing illustrator",
    duration: "4 Months",
  },
  {
    id: "server-infrastructure",
    title: "Diploma in Server Infrastructure",
    desc: "Aims to teach knowledge of deploying, building, designing, optimizing and operating technologies for Server Administrator Role.",
    avi: database,
    alt: "server infrastructure illustrator",
    duration: "4 Months",
  },
  {
    id: "hardware-networking",
    title: "Certificate in Hardware & Networking",
    desc: "Course on how to install, upgrade, repair, configure, optimize, troubleshoot, and perform preventative mentainance on basic PC hardware and OS. The program will also help in identifying and describing all the major networking technologies, system, skills and tools used in mordern PC base computer networks.",
    avi: hardware,
    alt: "hardware netorking illustrator",
    duration: "4 Months",
  },
  {
    id: "mobile-app-development",
    title: "Mobile App Development on the Android Platform",
    desc: "Proram focussess on application level APIs and imparts skills to develop user and data-centric mobile apps and utilities on the Android platform. Skills in Android app development include working with graphics, multimedia, connectivity and loction-based services. Learn to debug, deploy and test mobile applications.",
    avi: android,
    alt: "mobile app development illustrator",
    duration: "4 Months",
  },
  {
    id: "digital-marketing-1",
    title: "Professional Diploma in Digital Marketing I",
    desc: "It helps learners to understand how to plan, implement and manage a comprehensive digital marketing strategy in Search Engine Optimization (SEO), Search Engine Monetization (SEM), Mobile and Email Marketing, ORM.",
    avi: digitalmarketing,
    alt: "digital marketing illustrator",
    duration: "4 Months",
  },
  {
    id: "linux-oracle-database",
    title: "Program in Linux & Oracle Database Adminstration",
    desc: "Enables the learners with the knowledge of planning, installing, configuring, managing, querying and troubleshooting an oracle database",
    avi: database,
    alt: "linux oracle database",
    duration: "4 Months",
  },
  {
    id: "digital-marketing-2",
    title: "Professional Diploma in Digital Marketing II",
    desc: "It helps learners to understand how to plan, implement and manage a comprehensive digital marketing strategy in Search Engine Optimization (SEO), Search Engine Monetization (SEM), Mobile and Email Marketing, ORM.",
    avi: digitalmarketing2,
    alt: "digital marketing illustrator",
    duration: "4 Months",
  },
  {
    id: "data-analytics-2",
    title: "Professional Diploma in Data Analytics II",
    desc: "Data Analytics course is designed to make one proficient in Data Analytics and techniques starting from the fundamentals of statics going onto advanced use of tools like R and Python, Artificial Intelligence, Business Intelligence.",
    avi: analytics,
    alt: "data analytics illustrator",
    duration: "4 Months",
  },
  {
    id: "data-analytics-3",
    title: "Professional Diploma in Data Analytics III",
    desc: "Data Analytics course is designed to make one proficient in Data Analytics and techniques starting from the fundamentals of statics going onto advanced use of tools like R and Python, Artificial Intelligence, Business Intelligence.",
    avi: analytics2,
    alt: "data analytics illustrator",
    duration: "4 Months",
  },
  {
    id: "artificial-inteligence",
    title: "Artificial Inteligence with Python",
    desc: "Artificial intelligence is the intelligence demonstrated by machines, in contrast to the intelligence displayed by humans. This program will cover the basic concepts of various fields of artificial intelligence like Artificial Neural Networks, Natural Language Processing, Machine Learning, Deep Learning, Genetic algorithms etc., and its implementation in Python.",
    avi: ai,
    alt: "artificial intelligence illustrator",
    duration: "3 Months",
  },
  {
    id: "internet-of-things",
    title: "Internet of Things (IoT) for Architects",
    desc: "IoT architecture consists of the devices, network structure, and cloud technology that allows IoT devices to communicate with each other. A basic IoT architecture consists of three layers: Perception (the sensors, gadgets, and other devices) Network (the connectivity between devices)",
    avi: iot,
    alt: "iot illustrator",
    duration: "4 Months",
  },
  {
    id: "mastering-block-chain",
    title: "Mastering Block Chain (Web3)",
    desc: "Blockchain is a digital ledger that is designed to record transactions, and undertake digital asset management. They are almost immutable due to which they are recommended for archiving data and establishing trust. Valuable assets can be tracked easily on blockchains. Only permissible members get the rights to access blockchain records.",
    avi: blockchain,
    alt: "blockchain illustrator",
    duration: "4 Months",
  },
];

export const ShortTermCareerProgramme = [
  {
    id: "mis-skill",
    title: "Certificate in MIS Skills",
    desc: "The program will help applicants understand th information and tasks needed to manage organizations efficiently and effetively. ",
    avi: microsoft,
    alt: "mis illustrator",
    duration: "3 Months",
  },
  {
    id: "web-design",
    title: "Certificate in Web Design",
    desc: "Web design involes the plan, creation and coding internet sites and web pages, many of which combine text with sounds, pictures, graphics and video clips. A web designer is responsible for creating the design and layout of a website or web pages. It and can mean working on a brand new website or updating an already existing site.",
    avi: web,
    alt: "web design illustrator",
    duration: "3 Months",
  },
  {
    id: "python",
    title: "Certificate in Python Programming",
    desc: "Introducing basic programmes using python and implementing programming fundamentals ",
    avi: python,
    alt: "python illustrator",
    duration: "2 Months",
  },
  {
    id: "machine-learning",
    title: "Machine Learning with R",
    desc: "Introducing basic programmes using python and implementing programming fundamentals ",
    avi: machinelearning,
    alt: "machine lerning",
    duration: "2 Months",
  },
  {
    id: "multimedia",
    title: "Certificate in Multimedia Skills",
    desc: "Multimedia specialists design and create IT-based multimedia products such as websites, DVDs, and computer games that combine text with sounds, pictures, graphics, video-clips, virtual reality and digital animation. Relevant experience is desirable, along with familiarity with a range of computer design packages.",
    avi: multimedia,
    alt: "multimedia illustrator",
    duration: "3 Months",
  },
  {
    id: "desktop-publishing",
    title: "Certificate in Desktop Publishing Basic",
    desc: "This focuces on the production of printed matter by means of a desktop computer having a layout program that integrates text and graphics.",
    avi: desktop,
    alt: "desktop publishing illustrator",
    duration: "2 Months",
  },
  {
    id: "ms-office",
    title: "Certificate in Microsoft Office",
    desc: "Microsoft Office packages like MS Word will teach you to edit text documents, create templates, and automate the creation of tables of content. Proficient in Excel means running and creating functions, pivot tables, and charts. Plus, you can make slideshows in PowerPoint.",
    avi: microsoft,
    alt: "microsoft office illustrator",
    duration: "2 Months",
  },
  {
    id: "rdbms",
    title: "Certificate in RDBMS Essentials & T-SQL Programming",
    desc: "RDBMS and SQL skills equipt you with necessary knowledge to becomes data experts to maintain, create and retrieve information from relational databases, which separate data into columns and rows. It also allows them to access, update, manipulate, insert and modify data efficiently.",
    avi: database,
    alt: "rdbms illustrator",
    duration: "2 Months",
  },
  {
    id: "system-support-maintenance",
    title: "System Support & Maintenance (A+)",
    desc: "System Support and Maintenance involves computer systems and components covering installing, configuring, managing and troubleshooting a PC. This programme enables student to set up computer networks and mitigate computer security threats.",
    avi: maintenance,
    alt: "system maintanace illustration",
    duration: "3 Months",
  },
  {
    id: "network-support-maintenance",
    title: "Network Support & Maintenance (N+)",
    desc: "Network Support and Maintenance equits students with knowledge of networking concepts and the skills required to setup, manage, troubleshoot and secure networks.",
    avi: hardware,
    alt: "network support maintainance",
    duration: "3 Months",
  },
  {
    id: "it-infrastructure-library",
    title: "Certificate in IT Infrastructure Library (ITIL)",
    desc: "ITIL is the most widly adopted approach for IT Service Management in the world. It provides a practical, no-nonsense framework for identifying, planning, delivering and supporting IT services to business.",
    avi: infograph,
    alt: "it library illustrator",
    duration: "4 Months",
  },
  {
    id: "comptia-security",
    title: "CompTIA Security+",
    desc: "Build your knowledge and professional experience with computer hardware, OS and networks to implement basic sercurity services on network. The course will prepare you for the CompTIA Security + Certification examination (SYO-401).",
    avi: security,
    alt: "security illustrator",
    duration: "3 Months",
  },
  {
    id: "project-management",
    title: "Certificate in Project Management Practice(PMPS)",
    desc: "For those who wish to develop professionally, increase their project management skills, apply a formalized and standard-base approach to project management.",
    avi: pmp,
    alt: "project management illustrator",
    duration: "3 Months",
  },
];



// all courses
export const AllCourses = [
  {
    id: "software_engineering",
    title: "Software Engineering",
    desc: "Intensive 1 - 2 years program aimed at creating Full Stack Developers, Builds specific skill set in Python, REST, Angular JS, React and DevOps.",
    avi: software_eng,
    alt: "software engineering illustrator",
    duration: "18 Months",
  },
  {
    id: "infrastructure_management",
    title: "Infrastructure Manangement",
    desc: "Intensive 1 - 2 program aimed at creating skills in infrastructure management, build strong foundational skill sin Hardware & Networking support, Windows Server Infrastructure, Database Adminstrator.",
    avi: infrastructuremgt,
    alt: "infrastructure management infrastructure",
    duration: "24 Months",
  },
  {
    id: "big_data",
    title: "Big Data",
    desc: "Big data is high volume, high velocity information to process to enable enhanced decision making. it consists of Hadoco, Zookeeper, House, Hive, Storm Distributed Live Computing and Sqoop.",
    avi: bigdata,
    alt: "big data illustration",
    duration: "4 Months",
  },
  {
    id: "java-devops",
    title: "Enterprise Java with DevOps",
    desc: "Producing software for multiple platforms using JAVA with DevOps model, you are bringing together your development and your operation team, and their work is no longer in silos.",
    avi: java,
    alt: "java illustration",
    duration: "6 Months",
  },
  {
    id: "mis-skill",
    title: "Certificate in MIS Skills",
    desc: "The program will help applicants understand th information and tasks needed to manage organizations efficiently and effetively. ",
    avi: microsoft,
    alt: "mis illustrator",
    duration: "3 Months",
  },
  {
    id: "web-design",
    title: "Certificate in Web Design",
    desc: "Web design involes the plan, creation and coding internet sites and web pages, many of which combine text with sounds, pictures, graphics and video clips. A web designer is responsible for creating the design and layout of a website or web pages. It and can mean working on a brand new website or updating an already existing site.",
    avi: web,
    alt: "web design illustrator",
    duration: "3 Months",
  },
  {
    id: "python",
    title: "Certificate in Python Programming",
    desc: "Introducing basic programmes using python and implementing programming fundamentals ",
    avi: python,
    alt: "python illustrator",
    duration: "2 Months",
  },
  {
    id: "machine-learning",
    title: "Machine Learning with R",
    desc: "Introducing basic programmes using python and implementing programming fundamentals ",
    avi: machinelearning,
    alt: "machine lerning",
    duration: "2 Months",
  },
  {
    id: "multimedia",
    title: "Certificate in Multimedia Skills",
    desc: "Multimedia specialists design and create IT-based multimedia products such as websites, DVDs, and computer games that combine text with sounds, pictures, graphics, video-clips, virtual reality and digital animation. Relevant experience is desirable, along with familiarity with a range of computer design packages.",
    avi: multimedia,
    alt: "multimedia illustrator",
    duration: "3 Months",
  },
  {
    id: "desktop-publishing",
    title: "Certificate in Desktop Publishing Basic",
    desc: "This focuces on the production of printed matter by means of a desktop computer having a layout program that integrates text and graphics.",
    avi: desktop,
    alt: "desktop publishing illustrator",
    duration: "2 Months",
  },
  {
    id: "ms-office",
    title: "Certificate in Microsoft Office",
    desc: "Microsoft Office packages like MS Word will teach you to edit text documents, create templates, and automate the creation of tables of content. Proficient in Excel means running and creating functions, pivot tables, and charts. Plus, you can make slideshows in PowerPoint.",
    avi: microsoft,
    alt: "microsoft office illustrator",
    duration: "2 Months",
  },
  {
    id: "rdbms",
    title: "Certificate in RDBMS Essentials & T-SQL Programming",
    desc: "RDBMS and SQL skills equipt you with necessary knowledge to becomes data experts to maintain, create and retrieve information from relational databases, which separate data into columns and rows. It also allows them to access, update, manipulate, insert and modify data efficiently.",
    avi: database,
    alt: "rdbms illustrator",
    duration: "2 Months",
  },
  {
    id: "dotnet",
    title: "Diploma in .Net Technologies",
    desc: "Aims to teach progamming and thereafter, design and develop web based applications on cutting edge .NET technology",
    avi: dotnet,
    alt: "dot net illustrator",
    duration: "4 Months",
  },
  {
    id: "java",
    title: "Diploma in Java Technologies",
    desc: "The program will equipt you with skills for designing, implementing, and maintaining Java-based software and applications, contributing to all stages of the software development lifecycle. You will thoroughly analyze user requirements, envision system features, and define application functionality.",
    avi: java,
    alt: "java illustrator",
    duration: "4 Months",
  },
  {
    id: "web-development",
    title: "Diploma in Web Development",
    desc: "Skills in creating a web based dynamic application using PHP and MySQL",
    avi: web2,
    alt: "web development illustrator",
    duration: "4 Months",
  },
  {
    id: "digital-marketing",
    title: "Diploma in Digital Marketing",
    desc: "It helps learners to understand how to plan, implement and manage a comprehensive digital marketing strategy in Search Engine Optimization (SEO), Search Engine Monetization (SEM), Mobile and Email Marketing, ORM.",
    avi: digitalmarketing,
    alt: "digital marketing illustrator",
    duration: "4 Months",
  },
  {
    id: "server-infrastructure",
    title: "Diploma in Server Infrastructure",
    desc: "Aims to teach knowledge of deploying, building, designing, optimizing and operating technologies for Server Administrator Role.",
    avi: database,
    alt: "server infrastructure illustrator",
    duration: "4 Months",
  },
  {
    id: "hardware-networking",
    title: "Certificate in Hardware & Networking",
    desc: "Course on how to install, upgrade, repair, configure, optimize, troubleshoot, and perform preventative mentainance on basic PC hardware and OS. The program will also help in identifying and describing all the major networking technologies, system, skills and tools used in mordern PC base computer networks.",
    avi: hardware,
    alt: "hardware netorking illustrator",
    duration: "4 Months",
  },
  {
    id: "mobile-app-development",
    title: "Mobile App Development on the Android Platform",
    desc: "Proram focussess on application level APIs and imparts skills to develop user and data-centric mobile apps and utilities on the Android platform. Skills in Android app development include working with graphics, multimedia, connectivity and loction-based services. Learn to debug, deploy and test mobile applications.",
    avi: android,
    alt: "mobile app development illustrator",
    duration: "4 Months",
  },
  {
    id: "digital-marketing-1",
    title: "Professional Diploma in Digital Marketing I",
    desc: "It helps learners to understand how to plan, implement and manage a comprehensive digital marketing strategy in Search Engine Optimization (SEO), Search Engine Monetization (SEM), Mobile and Email Marketing, ORM.",
    avi: digitalmarketing,
    alt: "digital marketing illustrator",
    duration: "4 Months",
  },
  {
    id: "digital-marketing-2",
    title: "Professional Diploma in Digital Marketing II",
    desc: "It helps learners to understand how to plan, implement and manage a comprehensive digital marketing strategy in Search Engine Optimization (SEO), Search Engine Monetization (SEM), Mobile and Email Marketing, ORM.",
    avi: digitalmarketing2,
    alt: "digital marketing illustrator",
    duration: "4 Months",
  },
  {
    id: "data-analytics-2",
    title: "Professional Diploma in Data Analytics II",
    desc: "Data Analytics course is designed to make one proficient in Data Analytics and techniques starting from the fundamentals of statics going onto advanced use of tools like R and Python, Artificial Intelligence, Business Intelligence.",
    avi: analytics,
    alt: "data analytics illustrator",
    duration: "4 Months",
  },
  {
    id: "data-analytics-3",
    title: "Professional Diploma in Data Analytics III",
    desc: "Data Analytics course is designed to make one proficient in Data Analytics and techniques starting from the fundamentals of statics going onto advanced use of tools like R and Python, Artificial Intelligence, Business Intelligence.",
    avi: analytics2,
    alt: "data analytics illustrator",
    duration: "4 Months",
  },
  {
    id: "artificial-inteligence",
    title: "Artificial Inteligence with Python",
    desc: "Artificial intelligence is the intelligence demonstrated by machines, in contrast to the intelligence displayed by humans. This program will cover the basic concepts of various fields of artificial intelligence like Artificial Neural Networks, Natural Language Processing, Machine Learning, Deep Learning, Genetic algorithms etc., and its implementation in Python.",
    avi: ai,
    alt: "artificial intelligence illustrator",
    duration: "3 Months",
  },
  {
    id: "internet-of-things",
    title: "Internet of Things (IoT) for Architects",
    desc: "IoT architecture consists of the devices, network structure, and cloud technology that allows IoT devices to communicate with each other. A basic IoT architecture consists of three layers: Perception (the sensors, gadgets, and other devices) Network (the connectivity between devices)",
    avi: iot,
    alt: "iot illustrator",
    duration: "4 Months",
  },
  {
    id: "mastering-block-chain",
    title: "Mastering Block Chain (Web3)",
    desc: "Blockchain is a digital ledger that is designed to record transactions, and undertake digital asset management. They are almost immutable due to which they are recommended for archiving data and establishing trust. Valuable assets can be tracked easily on blockchains. Only permissible members get the rights to access blockchain records.",
    avi: blockchain,
    alt: "blockchain illustrator",
    duration: "4 Months",
  },
  {
    id: "system-support-maintenance",
    title: "System Support & Maintenance (A+)",
    desc: "System Support and Maintenance involves computer systems and components covering installing, configuring, managing and troubleshooting a PC. This programme enables student to set up computer networks and mitigate computer security threats.",
    avi: maintenance,
    alt: "system maintanace illustration",
    duration: "3 Months",
  },
  {
    id: "network-support-maintenance",
    title: "Network Support & Maintenance (N+)",
    desc: "Network Support and Maintenance equits students with knowledge of networking concepts and the skills required to setup, manage, troubleshoot and secure networks.",
    avi: hardware,
    alt: "network support maintainance",
    duration: "3 Months",
  },
  {
    id: "it-infrastructure-library",
    title: "Certificate in IT Infrastructure Library (ITIL)",
    desc: "ITIL is the most widly adopted approach for IT Service Management in the world. It provides a practical, no-nonsense framework for identifying, planning, delivering and supporting IT services to business.",
    avi: infograph,
    alt: "it library illustrator",
    duration: "4 Months",
  },
  {
    id: "comptia-security",
    title: "CompTIA Security+",
    desc: "Build your knowledge and professional experience with computer hardware, OS and networks to implement basic sercurity services on network. The course will prepare you for the CompTIA Security + Certification examination (SYO-401).",
    avi: security,
    alt: "security illustrator",
    duration: "3 Months",
  },
  {
    id: "project-management",
    title: "Certificate in Project Management Practice(PMPS)",
    desc: "For those who wish to develop professionally, increase their project management skills, apply a formalized and standard-base approach to project management.",
    avi: pmp,
    alt: "project management illustrator",
    duration: "3 Months",
  },
  {
    id: "linux-oracle-database",
    title: "Program in Linux & Oracle Database Adminstration",
    desc: "Enables the learners with the knowledge of planning, installing, configuring, managing, querying and troubleshooting an oracle database",
    avi: database,
    alt: "linux oracle database",
    duration: "4 Months",
  },
];

// Photo by <a href="https://unsplash.com/@thisisengineering?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">ThisisEngineering RAEng</a> on <a href="https://unsplash.com/s/photos/software-engineering?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/@joszczepanska?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jo Szczepanska</a> on <a href="https://unsplash.com/s/photos/management?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/@tvick?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Taylor Vick</a> on <a href="https://unsplash.com/s/photos/big-data?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
//Photo by <a href="https://unsplash.com/@tvick?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Taylor Vick</a> on <a href="https://unsplash.com/s/photos/big-data?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
//Photo by <a href="https://unsplash.com/@dawson2406?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Stephen Dawson</a> on <a href="https://unsplash.com/s/photos/big-data?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
//Photo by <a href="https://unsplash.com/es/@lukechesser?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Luke Chesser</a> on <a href="https://unsplash.com/s/photos/big-data?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
//Photo by <a href="https://unsplash.com/es/@lukechesser?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Luke Chesser</a> on <a href="https://unsplash.com/s/photos/big-data?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
//Photo by <a href="https://unsplash.com/@benceboros?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">BENCE BOROS</a> on <a href="https://unsplash.com/s/photos/iot?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
//Photo by <a href="https://unsplash.com/@onurbinay?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Onur Binay</a> on <a href="https://unsplash.com/s/photos/multimedia?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
//Photo by <a href="https://unsplash.com/@impelling?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Ed Hardie</a> on <a href="https://unsplash.com/s/photos/microsoft?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
//Photo by RealToughCandy.com: https://www.pexels.com/photo/text-11035547/

// Photo by Tara Winstead: https://www.pexels.com/photo/white-and-blue-butterflies-illustration-8849282/
//Photo by Digital Buggu: https://www.pexels.com/photo/colorful-toothed-wheels-171198/
// Photo by fauxels: https://www.pexels.com/photo/colleagues-looking-at-survey-sheet-3183153/
//Photo by Pixabay: https://www.pexels.com/photo/security-logo-60504/
// Photo by RODNAE Productions: https://www.pexels.com/photo/white-printer-paper-with-blue-and-green-color-7948099/


import React from 'react';
import { MdFacebook } from 'react-icons/md';
import {
  IoLogoGithub,
  IoLogoInstagram,
  IoLogoWhatsapp,
  IoMdCall,
  IoMdMail,
} from 'react-icons/io';
import niitLogo from '../../../assets/niit_logo.png';
import { Logo } from '../Header/Header.styles';
import {
  Address,
  Div,
  FooterBanner,
  IconLinks,
  LogoContainer,
  Nav,
  NavContainer,
  Policy,
  SocialIcons,
} from './Footer.styles';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <FooterBanner>
      <NavContainer>
        <LogoContainer>
          <figure>
            {/* <Niit>
              NIIT
            </Niit> */}
            <Logo src={niitLogo} alt="Niit logo" />
          </figure>
          <Address>@ 157, Upper Chime Avenue, New Haven, Enugu.</Address>
          <SocialIcons>
            <IconLinks
              href="https://wa.me?+2349063392695"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoWhatsapp title="Whatsapp" />
            </IconLinks>
            <IconLinks
              href="https://www.facebook.com/niitenugu/"
              target="_blank"
              rel="noreferrer"
            >
              <MdFacebook title="Facebook" />
            </IconLinks>
            <IconLinks
              href="https://www.instagram.com/niitenugu9/"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoInstagram title="Instagram" />
            </IconLinks>
            <IconLinks
              href="https://github.com/niitenugu/"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoGithub title="Github" />
            </IconLinks>
            <IconLinks
              href="mailto:niitenugu1@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <IoMdMail title="Email" />
            </IconLinks>
            <IconLinks
              href="tel:+2349063392695"
              target="_blank"
              rel="noreferrer"
            >
              <IoMdCall title="Phone Call" />
            </IconLinks>
          </SocialIcons>
        </LogoContainer>
        <Nav>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <p style={{ color: 'white' }}>Home</p>
          </Link>
          <Link to="/courses" style={{ textDecoration: 'none' }}>
            <p style={{ color: 'white' }}>Courses</p>
          </Link>
          <Link to="/about" style={{ textDecoration: 'none' }}>
            <p style={{ color: 'white' }}>About</p>
          </Link>
          <Link to="/contact" style={{ textDecoration: 'none' }}>
            <p style={{ color: 'white' }}>Contact</p>
          </Link>
        </Nav>
      </NavContainer>
      <Div>
        <Policy>
          ICMC Enugu &copy; 2022. All Right Reserved{'  '}
          <a href="http://www.niit.com/policy/pages/trademarks.aspx">
            Trademarks
          </a>{' '}
          |{' '}
          <a href="http://www.niit.com/policy/pages/privacy-statement.aspx">
            Privacy Statement
          </a>{' '}
          |{' '}
          <a href="http://www.niit.com/policy/pages/tearms-of-use.aspx">
            Terms of Use
          </a>
        </Policy>
        <Policy>
          <span>
            designed and develeped by{' '}
            <a href="https://st-pardon.netlify.app">St. Pardon Inc</a>
          </span>
          &nbsp;&nbsp;
          <a href="https://github.com/St-Pardon/">
            <IoLogoGithub style={{ display: 'inline' }} />
          </a>
        </Policy>
      </Div>
    </FooterBanner>
  );
};

export default Footer;

import React, { useRef, useState } from 'react';
import { Hr } from '../CommonStyles';
import {
  DetailsContainer,
  H2,
  Para,
  TestimonialsContainer,
} from './Testimonial.styles';
import { TestiomonialDetails } from '../../assets/Data/Data';
import Testimony from '../../component/Testimoney/Testimony.component';
import { Fade } from 'react-awesome-reveal';

const Testimonial = () => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const scroll = useRef();
  return (
    <TestimonialsContainer>
      <Fade bottom>
        <H2>What Others Say</H2>
      </Fade>
      <Hr style={{ margin: '20px auto' }} />
      <Para>
        From our clients, ex students and partner have to say about us
      </Para>
      <DetailsContainer
        className="testimonials"
        ref={scroll}
        onMouseDown={(e) => {
          setIsDown(true);
          setStartX(e.pageX - scroll.current.offsetLeft);
          setScrollLeft(scroll.current.scrollLeft);
        }}
        onMouseLeave={(e) => setIsDown(false)}
        onMouseUp={(e) => setIsDown(false)}
        onMouseMove={(e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - scroll.current.offsetLeft;
          const walk = (x - startX) * 2;
          scroll.current.scrollLeft = scrollLeft - walk;
        }}
      >
        {TestiomonialDetails.map((item, i) => (
          <Fade key={i} bottom>
            <Testimony
              key={i}
              quote={item.quote}
              name={item.name}
              role={item.role}
            />
          </Fade>
        ))}
      </DetailsContainer>
    </TestimonialsContainer>
  );
};

export default Testimonial;

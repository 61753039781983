import styled from "styled-components";
import { devices } from "../../assets/Data/Breakpoints";
import img from "../../assets/Images/backgrounds/gallary.jpg";

export const GallaryContainer = styled.section`
  width: 100%;
  background-color: #dddddd;
  padding-bottom: 20px;
`;

export const Header = styled.header`
  height: 200px;
  width: 100%;
  background: linear-gradient(to right, #0a0a0a77, #3f3f3f55, #0a0a0a77),
    url(${img}) center;
`;

export const PageTitle = styled.h2`
  text-align: center;
  line-height: 200px;
  font-size 3rem;
  color: #DDDDDD;
`;

export const GallarySlideContainer = styled.figure`
  padding: 20px;
  background-color: #ffffff;
  max-width: 1024px;
  border-radius: 4px;
  width: 90%;
  margin: 30px auto;
`;

export const GallarySlideDesc = styled.figcaption`
  margin: 5px 0;
  width: 60%;
  
  @media ${devices.tablet} {
   width: 100%;
  }
`;

export const GallarySlide = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  gap: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
`;


//Photo by <a href="https://unsplash.com/@korng_sok?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Korng Sok</a> on <a href="https://unsplash.com/s/photos/gallary?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>

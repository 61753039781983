import React, {useState } from "react";
import {
  Apply,
  Container,
  CourseDesc,
  CourseTitle,
  Details,
  Duration,
  Figure,
  Img,
} from "./CourseItem.style";
import { RiTimer2Line } from "react-icons/ri";
import Modal from '../Modal/ImageModal.component';
import EnrolmentForm from "../EnrolmentForm/EnrolmentForm.component";

const CourseItem = ({ id, title, desc, avi, alt, duration }) => {
    const [open, setOpen] = useState(false);
  return (
    <Container>
      <Figure>
        <Img src={avi} alt={alt} />
      </Figure>
      <Details>
        <CourseTitle>{title}</CourseTitle>
        <Duration>
          <RiTimer2Line style={{ fontSize: "1.2rem" }} />
          &nbsp;{duration}
        </Duration>
        <hr />
        <CourseDesc>{desc}</CourseDesc>
      </Details>
      <Apply onClick={() => setOpen(!open)}>Enrol</Apply>
      <Modal open={open} onClose={() => setOpen(!open)}>
        <EnrolmentForm id={title} />
      </Modal>
    </Container>
  );
};

export default CourseItem;

import styled, { css } from "styled-components";
import { devices } from "../../../assets/Data/Breakpoints";

export const HeaderBanner = styled.header`
  display: flex;
  position: sticky;
  top: 0;
  background-color: #fff;
  justify-content: space-around;
  padding: 0 20px;
  align-items: center;
  z-index: 10;
  
  @media ${devices.mobileL}{
    padding: 0;
  }
`;

export const Logo = styled.img`
  width: 200px;

  @media ${devices.mobileL}{
    width: 150px;
  }
`;

export const Span = styled.span`
  font-size: 1.4rem;
  font-family: "Aref Ruqaa Ink", serif;
  font-weight: 700;
  color: #0e3997;

 ${(props) => {
    switch (props.$mode) {
      case "footer":
        return css`
          color: white;
        `;
      default:
        return css``;
    }
  }}

  @media ${devices.mobileL} {
    font-size: 1.1rem;
  }
`;

export const Partition = styled.nav`
  width: 100%;

  ${(props) => {
    switch (props.$mode) {
      case "right":
        return css`
          display: flex;
          justify-content: flex-end;
        `;
      default:
        return css``;
    }
  }}
`;

export const Ul = styled.ul`
  display: flex;
  justify-content: flex-end;
  list-style: none;

  @media ${devices.tablet} {
    display: none;
  }
`;

export const Li = styled.li`
  font-size: 1.1rem;
  color: #0e3997;
  padding: 15px 25px;

  &:hover {
    color: #fff;
    background-color: #0e3997;
  }
`;

export const Hamburger = styled.div`
  font-size: 2rem;
  color: #0000ff;
  padding: 15px 30px;
  display: none;
  cursor: pointer;

  @media ${devices.tablet} {
    display: block;
  }
`;

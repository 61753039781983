import React from "react";
import { Link } from "react-router-dom";
import { Overlay, SidebarC } from "./Sidemenu.styles";

const Sidemenu = ({ display, closeBar, path }) => {
  return (
    <Overlay $mode={display ? "open" : "close"} onClick={closeBar}>
      <SidebarC $mode={display ? "open" : "close"} onClick={closeBar}>
        <Link to="/" onClick={closeBar}>
          <p>Home</p>
        </Link>
        <Link
          to="/courses"
          onClick={() => {
            closeBar();
            path("courses");
          }}
        >
          <p>Courses</p>
        </Link>
        <Link
          to="/gallery"
          onClick={() => {
            closeBar();
            path("gallery");
          }}
        >
          <p>Gallery</p>
        </Link>
        <Link
          to="/about"
          onClick={() => {
            closeBar();
            path("about");
          }}
        >
          <p>About</p>
        </Link>
        <Link
          to="/contact"
          onClick={() => {
            closeBar();
            path("contact");
          }}
        >
          <p>Contact</p>
        </Link>
      </SidebarC>
    </Overlay>
  );
};

export default Sidemenu;

import React from "react";
import { GoQuote } from "react-icons/go";
import { Details, Name, Quote, Role } from "./Testimoney.styles";

const Testimony = ({ quote, name, role }) => {
  return (
    <Details>
      <GoQuote
        style={{
          position: "absolute",
          fontSize: "5rem",
          left: "0",
          color: "#00000022",
        }}
      />
      <Quote>{quote}</Quote>
      <Name>&#3899;{name}&#3898;</Name>
      <Role>{role}</Role>
    </Details>
  );
};

export default Testimony;

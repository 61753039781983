import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
// import Banner from "../../component/Banner/Banner.component";
// import { contentObject } from "../../assets/Data/Data";
import { images } from "../../assets/Data/carouselData";

const CarouselContainer = () => {
  //control carousel width
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimention = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimention);
    return () => window.removeEventListener("resize", updateDimention);
  }, []);

  return (
    <Carousel
      autoPlay={true}
      swipeable
      showIndicators={false}
      showThumbs={false}
      infiniteLoop
      showStatus={false}
      centerMode
      centerSlidePercentage={
        width > 1200 ? 40 : width > 1000 ? 60 : width > 900 ? 70 : 100
      }
      height={400}
    >
      {/* {contentObject.map((item, i) => {
        return (
          <Banner
            key={i}
            title={item.title}
            details={item.details}
            button={item.button}
            img={item.img}
            slide={item.slide}
            link={item.link}
          />
        );
      })} */}
      {images.map((img, i) => (
        <figure style={{ height: "100%"}} key={i}>
          <img
            src={img}
            alt="niit images"
            style={{ height: "100%", width: "100%" }}
          />
        </figure>
      ))}
    </Carousel>
  );
};

export default CarouselContainer;

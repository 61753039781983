import styled, { css } from "styled-components";

export const Hr = styled.hr`
  width: 30%;
  margin: 0 auto;
  ight: 5px;
  background-color: rgb(104, 86, 242);
  rder: none;
  border-radius: 100%;

  ${(props) =>
    props.partner &&
    css`
      margin: 20px auto;
    `}
`;

export const Img = styled.img`
  width: 100%;
`;

export const IframeContainer = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding: 30px 0 400px 0;
  overflow: hidden;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer/FooterBanner.component';
import Header from './Header/Header.component';

const Navigation = ({path}) => {
  return (
    <>
    <Header path={path} />
    <Outlet />
    <Footer />
    </>
  )
}

export default Navigation
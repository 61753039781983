import { useState } from 'react';
import { Img, LargeImg } from './GallaryItem.styles';
import Modal from '../../component/Modal/ImageModal.component';

const GallaryItem = ({ src, alt, largesrc }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Img
        src={src}
        alt={alt}
        onClick={() => {
          setOpen(!open);
        }}
      />
      <Modal open={open} onClose={() => setOpen(!open)}>
        <LargeImg src={largesrc} alt={alt} />
      </Modal>
    </>
  );
};

export default GallaryItem;

import coding_illustration from "../illustrations/coding_illustration.PNG";
import global_certification from "../illustrations/awards-users-people.PNG";
import technology from "../illustrations/coding-application-development.PNG";
import img1 from "../Images/Niit-images/niit-img10.jpg"
import img2 from "../Images/Niit-images/niit-img7.jpg"
import img3 from "../Images/Niit-images/niit-img18.jpg"
//course categories data
export const categories = [
  { status: "image", src: img1 },
  {
    status: "text",
    title: "Revolutionary Programme - Master Mind Series",
    summary:
      "This category includes professional diploma courses that spans between 4 - 6 months and are designed to equip students with the necessary skills to enter the job market. These courses are ideal for students looking to start their professional careers in a particular field of study.",
    courses: "Data, Big Data, Digital Marketing, Java Enterprises with DevOps",
    route: "Master_Mind_Series",
  },
  {
    status: "text",
    title: "Digital Transformation Programme - DigiNxt Series",
    summary:
      "This category includes long term full stack courses spanning a period of 1 year + . These courses are designed to help you learn the fundamentals of development, as well as give you an opportunity to expand your skillset and gain experience in different domains. You will also gain hands-on experience building real-world applications that can be deployed in production environments.",
    courses:
      "Software Engineering: Full Stack Developer, Infrastruct Management",
    route: "DigiNxt_Series",
  },
  { status: "image", src: img2 },
  {
    status: "text",
    title: "Short Term Career Enhancement Programme",
    summary:
      "The short term certification courses are designed to be a quick and easy way to gain specific knowledge of a particular skill set without having to commit to an entire degree program. These programs can be taken in person at one of our many locations around the world or online via our Open Education platform. it can span for a duration of 2 - 4 months.",
    courses:
      "Web Design, Python Programming, Microsoft Packages, Graphics Design, Java",
    route: "Career_Enhancement_Programme",
  },
  {
    status: "text",
    title: "Rapid Employability Diploma Programme",
    summary:
      "Our diploma courses span between 3 - 6 months. the programme focuses on broadening you knowledge and skill on a particular programming stack. It will equip you with the knowledge and skills to build complex applications using advanced technologies.",
    courses:
      ".NET Technologies, Java Technologies, Web Development, Server Infrastructure",
    route: "Employability_Diploma_Programme",
  },
  { status: "image", src: img3 },
];

// home page banner section data
export const contentObject = [
  {
    title: "Learn Programming",
    details:
      "Are you interested in learning a programming language? Do you want to learn how to code but don't know where to start? The Introduction to Programming for Beginners course is for you! Learn how to program using Python, one of the world's most popular programming languages.",
    button: "Find Out How",
    img: coding_illustration,
    slide: "",
    link: "/courses",
  },
  {
    title: "State of the art technology",
    details:
      "Our State of the art technologies and conducive environment help us to create the best possible environment for you to learn. We understand that every student is unique, and therefore, we take great care in understanding your needs so that we can provide them with a customised learning experience.",
    button: "Learn More",
    img: technology,
    slide: "slide2",
    link: "/about",
  },
  {
    title: "Globally Accepted Certificate",
    details:
      "Earn a certification that is globally recognized, globally accepted and globally valued. To give you that competitative edge in the job market and improving your skills in a technological advancing world.",
    button: "Learn More",
    img: global_certification,
    slide: "slide1",
    link: "/#whyus",
  },
];

// Testiomonial section data

export const TestiomonialDetails = [
  {
    imgSrc: "",
    imgAlt: "Chidi Ofia's pic",
    quote:
      "I get most excited about the small class sizes and the hands-on experience that students get in this program. The program director is also great at making things fun for the students to learn.",
    name: "Chidi Ofia",
    role: "Ex Student",
  },
  {
    imgSrc: "",
    imgAlt: "Florence Michael's pic",
    quote:
      "I really love that this company has so many young and eager minds focussed on learning something new. These are the people who will be building the next big thing. I'm happy to be part of the team and help them in making the world a better place by learning to code",
    name: "Florence Michael",
    role: "Sr. Software Engineer",
  },
  {
    imgSrc: "",
    imgAlt: "Rotimi Aderibigbe's pic",
    quote:
      "My name is Rotimi, and I have been living here for five months. I enjoy it very much. The teachers are very nice to me, and they make me feel at home.",
    name: "Rotimi Aderibigbe",
    role: "Ex Student",
  },
  {
    imgSrc: "",
    imgAlt: "Chidi Nnam's pic",
    quote: "I have been here for 5 months, and I enjoy it very much. The teachers are very nice to me, and they make me feel at home.",
    name: "Chidi Nnam",
    role: "Web Developer",
  },
  {
    imgSrc: "",
    imgAlt: "Fummi Adekoya's pic",
    quote: "I have been very impressed by the teachers. Their patience and dedication has helped me a lot.",
    name: "Fummi Adekoya",
    role: "Ex Student",
  },
];

import { useState, useRef } from 'react';
import {
  GallaryContainer,
  GallarySlide,
  GallarySlideContainer,
  GallarySlideDesc,
  Header,
  PageTitle,
} from './Gallary.styles';
import {
  AIBasin,
  Excursion,
  FrscTraining,
  NyscCds,
  OurOffice,
  SSpeter,
} from '../../assets/Data/GallaryData';
import GallaryItem from '../../component/GallaryItem/GallaryItem';
import { Fade } from 'react-awesome-reveal';

const Gallary = () => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const slider1 = useRef();
  const slider2 = useRef();
  const slider3 = useRef();
  const slider4 = useRef();
  const slider5 = useRef();
  const slider6 = useRef();

  return (
    <GallaryContainer>
      <Header>
        <PageTitle>Gallery</PageTitle>
      </Header>
      <GallarySlideContainer>
        <GallarySlide
          ref={slider1}
          onMouseDown={(e) => {
            setIsDown(true);
            setStartX(e.pageX - slider1.current.offsetLeft);
            setScrollLeft(slider1.current.scrollLeft);
          }}
          onMouseLeave={(e) => setIsDown(false)}
          onMouseUp={(e) => setIsDown(false)}
          onMouseMove={(e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider1.current.offsetLeft;
            const walk = (x - startX) * 2;
            slider1.current.scrollLeft = scrollLeft - walk;
          }}
        >
          {AIBasin.map((item, i) => (
            <Fade bottom>
              <GallaryItem
                key={i}
                src={item.thumbnail}
                alt={item.alt}
                largesrc={item.img}
              />
            </Fade>
          ))}
        </GallarySlide>
        <GallarySlideDesc>
          The training of the Anambra-Imo River Basin Development Authority
          Owerri, Imo Staff on Budget/Budgetary Control Management at our Enugu
          Office June, 2022{' '}
        </GallarySlideDesc>
      </GallarySlideContainer>
      <GallarySlideContainer>
        <GallarySlide
          ref={slider6}
          onMouseDown={(e) => {
            setIsDown(true);
            setStartX(e.pageX - slider6.current.offsetLeft);
            setScrollLeft(slider6.current.scrollLeft);
          }}
          onMouseLeave={(e) => setIsDown(false)}
          onMouseUp={(e) => setIsDown(false)}
          onMouseMove={(e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider6.current.offsetLeft;
            const walk = (x - startX) * 2;
            slider6.current.scrollLeft = scrollLeft - walk;
          }}
        >
          {SSpeter.map((item, i) => (
            <Fade bottom>
              <GallaryItem
                key={i}
                src={item.thumbnail}
                alt={item.alt}
                largesrc={item.img}
              />
            </Fade>
          ))}
        </GallarySlide>
        <GallarySlideDesc>
          Tech sensitization of Catholic Youth Organization of Nigeria (CYON)
          during their annual youth week at SS Peter and Paul Catholic Church,
          Abakpa Enugu.
        </GallarySlideDesc>
      </GallarySlideContainer>
      <GallarySlideContainer>
        <GallarySlide
          ref={slider2}
          onMouseDown={(e) => {
            setIsDown(true);
            setStartX(e.pageX - slider2.current.offsetLeft);
            setScrollLeft(slider2.current.scrollLeft);
          }}
          onMouseLeave={(e) => setIsDown(false)}
          onMouseUp={(e) => setIsDown(false)}
          onMouseMove={(e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider2.current.offsetLeft;
            const walk = (x - startX) * 2;
            slider2.current.scrollLeft = scrollLeft - walk;
          }}
        >
          {Excursion.map((item, i) => (
            <Fade left>
              <GallaryItem
                key={i}
                src={item.thumbnail}
                alt={item.alt}
                largesrc={item.img}
              />
            </Fade>
          ))}
        </GallarySlide>
        <GallarySlideDesc>
          Roseville Secondary School Excursion to IGNA ICT Consulting Enugu's Office, to learn,
          interact and visualise tech facilitites
        </GallarySlideDesc>
      </GallarySlideContainer>
      <GallarySlideContainer>
        <GallarySlide
          ref={slider3}
          onMouseDown={(e) => {
            setIsDown(true);
            setStartX(e.pageX - slider3.current.offsetLeft);
            setScrollLeft(slider3.current.scrollLeft);
          }}
          onMouseLeave={(e) => setIsDown(false)}
          onMouseUp={(e) => setIsDown(false)}
          onMouseMove={(e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider3.current.offsetLeft;
            const walk = (x - startX) * 2;
            slider3.current.scrollLeft = scrollLeft - walk;
          }}
        >
          {OurOffice.map((item, i) => (
            <Fade left>
              <GallaryItem
                key={i}
                src={item.thumbnail}
                alt={item.alt}
                largesrc={item.img}
              />
            </Fade>
          ))}
        </GallarySlide>
        <GallarySlideDesc>
          Our Office block, reception, facilitites and classrooms
        </GallarySlideDesc>
      </GallarySlideContainer>
      <GallarySlideContainer>
        <GallarySlide
          ref={slider4}
          onMouseDown={(e) => {
            setIsDown(true);
            setStartX(e.pageX - slider4.current.offsetLeft);
            setScrollLeft(slider4.current.scrollLeft);
          }}
          onMouseLeave={(e) => setIsDown(false)}
          onMouseUp={(e) => setIsDown(false)}
          onMouseMove={(e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider4.current.offsetLeft;
            const walk = (x - startX) * 2;
            slider4.current.scrollLeft = scrollLeft - walk;
          }}
        >
          {FrscTraining.map((item, i) => (
            <Fade left>
              <GallaryItem
                key={i}
                src={item.thumbnail}
                alt={item.alt}
                largesrc={item.img}
              />
            </Fade>
          ))}
        </GallarySlide>
        <GallarySlideDesc>
          Training Session for the Fedral Road Safty Corp (FRSC) at their Enugu
          HQ May 2021
        </GallarySlideDesc>
      </GallarySlideContainer>
      <GallarySlideContainer>
        <GallarySlide
          ref={slider5}
          onMouseDown={(e) => {
            setIsDown(true);
            setStartX(e.pageX - slider5.current.offsetLeft);
            setScrollLeft(slider5.current.scrollLeft);
          }}
          onMouseLeave={(e) => setIsDown(false)}
          onMouseUp={(e) => setIsDown(false)}
          onMouseMove={(e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider5.current.offsetLeft;
            const walk = (x - startX) * 2;
            slider5.current.scrollLeft = scrollLeft - walk;
          }}
        >
          {NyscCds.map((item, i) => (
            <Fade left>
              <GallaryItem
                key={i}
                src={item.thumbnail}
                alt={item.alt}
                largesrc={item.img}
              />
            </Fade>
          ))}
        </GallarySlide>
        <GallarySlideDesc>
          Tech Sensitization for Corp Memebers during their weekly Community
          Development Servics (CDS) at the NYSC Enugu North LGA Office{' '}
        </GallarySlideDesc>
      </GallarySlideContainer>
    </GallaryContainer>
  );
};

export default Gallary;

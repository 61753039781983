import styled from "styled-components";
import { devices } from "../../assets/Data/Breakpoints";

export const WelcomeSection = styled.section`
  text-align: center;
  padding: 20px 0;
`;

export const H2 = styled.h2`
  padding: 20px 0;
  font-size: 1.6rem;

  @media ${devices.mobileL} {
    font-size: 1.2rem;
  }
`;

export const Para = styled.p`
  padding: 20px 0;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;

  @media ${devices.tablet} {
    padding: 15px 5px;
  }

  @media ${devices.mobileL} {
    font-size: 1rem;
  }
`;

export const AtNiit = styled.div`
  display: flex;
  column-gap: 30px;
  justify-content: center;

  @media ${devices.mobileL} {
    flex-wrap: wrap;
  }
`;

export const Figure = styled.figure`
  width: 100%;
  max-width: 200px;

  &:hover {
    transform: scale(1.1);
    transition: 800ms;
  }

  @media ${devices.mobileL} {
    max-width: 120px;
  }
`;
import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import {
  IoMdCall,
  IoMdMail,
  IoMdMap,
  IoLogoInstagram,
  IoLogoWhatsapp,
} from "react-icons/io";
import { MdFacebook } from "react-icons/md";
import { RiUserLine } from "react-icons/ri";
import {
  Btn,
  ContactContainer,
  ContactIcon,
  ContactIconContainer,
  ContactMethod,
  Fieldset,
  Input,
  Label,
  LeftContainer,
  Para,
  RightContainer,
  SLink,
  Socials,
  TextArea,
  Title,
} from "./Contact.styles";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const form = useRef();
  const submit = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ccpofil",
        "template_o7jboc4",
        form.current,
        "F_Yih3_3kRppuuPms"
      )
      .then(
        (result) => {
          submit.current.style.backgroundColor = 'green'
          submit.current.style.innerHTML = "Sucess";
          console.log(result.text);
        },
        (error) => {
          submit.current.style.backgroundColor = "red";
          submit.current.style.innerHTML = "Failed";
          console.log(error.text);
        }
      );
    setName("");
    setEmail("");
    setMsg("");
  };
  return (
    <ContactContainer>
      <LeftContainer>
        <div>
          <Title>Get In Touch</Title>
          <Para>
            Fill up the form and our team will get back to you within 24 hours.
          </Para>
        </div>
        <ContactIconContainer>
          <a href="tel:+2349063392695" target="_blank" rel="noreferrer">
            <ContactIcon title="Click to call us directly">
              <IoMdCall />
              <ContactMethod>+234 (090) 6339 2695</ContactMethod>
            </ContactIcon>
          </a>
          <a href="tel:+2349013404778" target="_blank" rel="noreferrer">
            <ContactIcon title="Click to call us directly">
              <IoMdCall />
              <ContactMethod>+234 (090) 1340 4778</ContactMethod>
            </ContactIcon>
          </a>
          <a
            href="mailto:niitenugu1@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <ContactIcon title="Click to send us a mail">
              <IoMdMail />
              <ContactMethod>niitenugu1@gmail</ContactMethod>
            </ContactIcon>
          </a>
          <a
            href="https://goo.gl/maps/WAyShW2s1BjhF2yWA"
            target="_blank"
            rel="noreferrer"
          >
            <ContactIcon title="Click to get a direction">
              <IoMdMap />
              <ContactMethod>157, Upper Chime, New Haven</ContactMethod>
            </ContactIcon>
          </a>
        </ContactIconContainer>
        <div>
          <h3 style={{ textAlign: "center", color: "#0000FF" }}>
            follows us on social media
          </h3>
          <Socials>
            <SLink
              href="https://www.facebook.com/niitenugu/"
              target="_blank"
              rel="noreferrer"
            >
              <MdFacebook title="Facebook" />
            </SLink>
            <SLink
              href="https://www.instagram.com/niitenugu9/"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoInstagram title="Instagram" />
            </SLink>
            <SLink
              href="https://wa.me/2349063392695?text=hello%20Dear"
              target="_blank"
              rel="noreferrer"
            >
              <IoLogoWhatsapp />
            </SLink>
          </Socials>
        </div>
      </LeftContainer>
      <RightContainer ref={form} onSubmit={handleSubmit}>
        <Label htmlFor="name">Name</Label>
        <Fieldset>
          <RiUserLine />
          <Input
            type="text"
            id="name"
            name="user_name"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Fieldset>
        <Label htmlFor="email">Email</Label>
        <Fieldset>
          <IoMdMail />
          <Input
            type="email"
            id="email"
            name="user_email"
            placeholder="someone@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Fieldset>
        <Label htmlFor="message">Message</Label>
        <Fieldset>
          <TextArea
            id="massage"
            placeholder="Type your message..."
            name="message"
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
          />
        </Fieldset>
        <Btn ref={submit}>Send Message</Btn>
      </RightContainer>
    </ContactContainer>
  );
};

export default Contact;

import { Hr, Img } from '../CommonStyles';
import { AtNiit, Figure, H2, Para, WelcomeSection } from './Welcome.styles';
import { Iframe, IframeContainer } from '../CommonStyles';
import learn from '../../assets/illustrations/learn.PNG';
import practice from '../../assets/illustrations/practice.PNG';
import connect from '../../assets/illustrations/connect.PNG';
import produce from '../../assets/illustrations/produce.PNG';
import { Fade } from 'react-awesome-reveal';
const Welcome = () => {
  return (
    <WelcomeSection>
      <Fade bottom>
        <H2>Welcome to IGNA ICT & Consulting Limited Enugu</H2>
      </Fade>
      <Hr />
      <IframeContainer>
        <Iframe
          src="https://www.youtube.com/embed/HTgolCLkvtE"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></Iframe>
      </IframeContainer>
      <Para>
        IGNA ICT & Consulting Limited NÉE NIIT Enugu is one of the over 20
        operational centres in different regions of Nigeria and is acknowledged
        as the undisputed leader in the country's IT training and education
        segment offering the latest IT programs mapped to the industry
        requirement and international vendor certifications.
      </Para>
      <AtNiit>
        <Fade bottom>
          <Figure>
            <Img src={learn} alt="illustration of learning" />
            <figcaption>We Learn</figcaption>
          </Figure>
        </Fade>
        <Fade bottom>
          <Figure>
            <Img src={practice} alt="illustration of learning" />
            <figcaption>We Practice</figcaption>
          </Figure>
        </Fade>
        <Fade bottom>
          <Figure>
            <Img src={connect} alt="illustration of learning" />
            <figcaption>We Connect</figcaption>
          </Figure>
        </Fade>
        <Fade bottom>
          <Figure>
            <Img src={produce} alt="illustration of learning" />
            <figcaption>We Produce</figcaption>
          </Figure>
        </Fade>
      </AtNiit>
    </WelcomeSection>
  );
};

export default Welcome;

import styled, { css } from "styled-components";
import compound from "../../assets/Images/Facilities/compound-main.jpg";
import lobby1 from "../../assets/Images/Facilities/lobby1-main.jpg";
import class4 from "../../assets/Images/Facilities/class2-main.jpg";
import server from "../../assets/Images/Facilities/server-room-main.jpg";
import outside from "../../assets/Images/Facilities/outside-main.jpg";
import mainbuilding from "../../assets/Images/Facilities/main-building-main.jpg";
import signpost from "../../assets/Images/Facilities/sign-post-main.jpg";
import class1 from "../../assets/Images/Facilities/classroom_1_Thumbs.jpg";
import class2 from "../../assets/Images/Facilities/classroom_10_Thumbs.jpg";
import { devices } from "../../assets/Data/Breakpoints";

export const AboutContainer = styled.section`
  width: 100%;
`;

export const Header = styled.section`
  background-color: #061a2a;
`;

export const NicheContainer = styled.div`
  width: 50%;
  padding: 50px;

  @media ${devices.tablet} {
    width: 90%;
  }
  @media ${devices.mobileL} {
    width: 100%;
  }
`;

export const Niche = styled.h3`
  color: #7a77c6;
  text-transform: capitalize;
  font-size: 1.5rem;
`;
export const NichePara = styled.p`
  text-transform: uppercase;
  color: white;
  margin-top: 10px;
`;

export const Gallary = styled.div`
  width: 100%;
  overflow-x: scroll;
  padding: 0 5px;
  display: flex;
  gap: 10px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Figure = styled.figure`
  border-radius: 10px;
  height: 300px;

  ${(props) => {
    switch (props.$mode) {
      case "slide1":
        return css`
          min-width: 250px;
          background-color: blue;
          background: url(${compound});
        `;
      case "slide2":
        return css`
          min-width: 400px;
          background-color: yellow;
          background: url(${class1});
        `;
      case "slide3":
        return css`
          min-width: 400px;
          background-color: yellow;
          background: url(${lobby1});
        `;
      case "slide4":
        return css`
          min-width: 250px;
          background-color: red;
          background: url(${class4});
        `;
      case "slide5":
        return css`
          min-width: 400px;
          background-color: purple;
          background: url(${server});
        `;
      case "slide6":
        return css`
          min-width: 250px;
          background-color: pink;
          background: url(${class2});
        `;
      case "slide7":
        return css`
          min-width: 250px;
          background-color: pink;
          background: url(${signpost});
        `;
      case "special":
        return css`
          min-width: 400px;
          height: 100%;
          grid-area: 1/3/3/4;
          background-color: red;
          background: url(${outside});
        `;
      default:
        return css`
          min-width: 400px;
          background-color: red;
          background: url(${mainbuilding});
        `;
    }
  }}

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media ${devices.tablet} {
    height: 200px;
    ${(props) => {
      switch (props.$mode) {
        case "slide1":
          return css`
            min-width: 200px;
          `;
        case "slide2":
          return css`
            min-width: 280px;
          `;
        case "slide3":
          return css`
            min-width: 200px;
          `;
        case "slide4":
          return css`
            min-width: 280px;
          `;
        case "slide5":
          return css`
            min-width: 200px;
          `;
        case "slide6":
          return css`
            min-width: 200px;
          `;
        case "slide7":
          return css`
            min-width: 200px;
          `;
        case "special":
          return css`
            min-width: 280px;
            grid-area: 1/1/2/2;
          `;
        default:
          return css`
            min-width: 280px;
          `;
      }
    }}
  }
  @media ${devices.mobileL} {
    height: 150px;
    ${(props) => {
      switch (props.$mode) {
        case "slide1":
          return css`
            min-width: 120px;
          `;
        case "slide2":
          return css`
            min-width: 180px;
          `;
        case "slide3":
          return css`
            min-width: 180px;
          `;
        case "slide4":
          return css`
            min-width: 180px;
          `;
        case "slide5":
          return css`
            min-width: 120px;
          `;
        case "slide6":
          return css`
            min-width: 120px;
          `;
        case "slide7":
          return css`
            min-width: 120px;
          `;
        case "special":
          return css`
            grid-area: 1/1/2/2;
            min-width: 180px;
          `;
        default:
          return css`
            min-width: 180px;
          `;
      }
    }}
  }
`;

export const OurStory = styled.article`
  width: 60%;
  margin: 50px auto;

  @media ${devices.tablet} {
    width: 80%;
    font-size: 0.9rem;
  }

  @media ${devices.mobileL} {
    width: 90%;
    font-size: 0.8rem;
  }
`;

export const OurValues = styled.article`
  width: 100%;
  margin: 30px 0 0 0;
  padding: 100px;
  background-color: #061a2a;
  color: #6b7a86;

  @media ${devices.tablet} {
    padding: 20px;
  }
`;

export const Heading = styled.h3`
  margin: 10px 0;
  font-size: 1.5rem;

  ${(props) => {
    switch (props.$mode) {
      case "value":
        return css`
          color: #7a77c6;
        `;
      case "growth":
        return css`
          font-size: 2.2rem;
          margin: 0;
        `;
      default:
        return css``;
    }
  }}
`;

export const Heading4 = styled.h4`
  margin: 5px 0;
  font-size: 1.2rem;
  color: #fff;
`;

export const Para = styled.p`
  text-align: justify;
  ${(props) => {
    switch (props.$mode) {
      case "value":
        return css`
          font-size: 1rem;
          color: #6b7a86;
        `;
      case "val-intro":
        return css`
          font-size: 1rem;
          color: #6b7a86;
          margin-bottom: 20px;
        `;
      case "growth":
        return css`
          background-color: #fff;
          padding: 20px;
          border-radius: 5px;
          text-transform: capitalize;
        `;
      default:
        return css`
          margin: 3px 0 50px 0;
        `;
    }
  }}
  @media ${devices.mobileL} {
    font-size: 0.9rem;
  }
`;

export const IconWrap = styled.i`
  padding: 5px 10px;
  border-radius: 100%;
  background-color: #6b7a86;
  color: blue;
`;

export const ValueContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 400px));
  gap: 30px;
  font-size: 2rem;

  @media ${devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.mobileL} {
    grid-template-columns: minmax(200px, 1fr);
    grid-template-rows: repeat(5, minmax(150px, auto));
  }
`;

export const Value = styled.div`
  ${(props) => {
    switch (props.$mode) {
      case "value1":
        return css`
          grid-area: 1/2/2/3;
        `;
      case "value2":
        return css`
          grid-area: 2/1/3/2;
        `;
      case "value3":
        return css`
          grid-area: 2/2/3/3;
        `;
      default:
        return css`
          grid-area: 1/1/2/2;
        `;
    }
  }}

  @media ${devices.tablet} {
    ${(props) => {
      switch (props.$mode) {
        case "value1":
          return css`
            grid-area: ;
          `;
        case "value2":
          return css`
            grid-area: 2/1/3/2;
          `;
        case "value3":
          return css`
            grid-area: 2/2/3/3;
          `;
        default:
          return css`
            grid-area: 3/1/3/4;
          `;
      }
    }}
  }
  @media ${devices.mobileL} {
    ${(props) => {
      switch (props.$mode) {
        case "value1":
          return css`
            grid-area: 2/1/2/2;
          `;
        case "value2":
          return css`
            grid-area: 3/1/3/2;
          `;
        case "value3":
          return css`
            grid-area: 4/1/4/2;
          `;
        default:
          return css`
            grid-area: 5/1/5/2;
          `;
      }
    }}
  }
`;

export const Span = styled.span`
  ${(props) => {
    switch (props.$mode) {
      case "niche":
        return css`
          color: blue;
        `;
      case "catchphrase":
        return css`
          color: yellow;
        `;
      case "growth":
        return css`
          font-size: 1.3rem;
          color: #7a77c6;
        `;
      case "green":
        return css`
          color: #00ff00;
        `;
      default:
        return css``;
    }
  }}
`;

export const Growth = styled.section`
  display: flex;
  justify-content: space-evenly;
  padding: 100px 0;
  background-color: #e4e4e4;

  @media ${devices.tablet} {
    flex-direction: column;
    gap: 20px;
    padding: 30px 10px;
  }
`;

export const GrowthGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, 300px));
  gap: 30px;

  @media ${devices.mobileL} {
    gap: 20px;
  }
`;

import styled from "styled-components";
import { devices } from "../../assets/Data/Breakpoints";

export const Details = styled.div`
  width: 320px;
  background-color: #fff;
  box-shadow: 5px 5px 4px 3px rgba(0, 0, 0, 0.23);
  padding: 20px 5px;
  border-radius: 5px;
  margin-top: 30px;
  position: relative;
  height: 100%;

  &:hover {
    scale: 1.03;
  }

  @media ${devices.tablet} {
    min-width: 300px;
  }

  @media ${devices.mobileM} {
    max-width: 280px;
    min-width: 280px;
  }
`;

export const Quote = styled.blockquote`
  margin: 30px auto;
`;

export const Name = styled.h3`
  ${Details}:hover & {
    color: blue;
  };

  @media ${devices.tablet}{
    font-size: 1rem;
  }
`;

export const Role = styled.p`
  @media ${devices.tablet} {
    font-size: 0.9rem;
  }
`;

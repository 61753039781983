import styled from "styled-components";

export const Category = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 20px 30px;
  background-color: #f4f4f4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CategoryName = styled.h3`
  font-size: 1.2rem;
  color: skyblue;
`;

export const CategorySummary = styled.p`
  font-size: 0.8rem;
`;

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
`;

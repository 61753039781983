import React from "react";
import { Link } from "react-router-dom";
import { Code, Container, Desc, Error, Safty } from "./Enonet.styles";

const Enonet = () => {
  return (
    <Container>
      <Error>
        <Code>404</Code>
        <Desc>The Resource you are trying to access does not exist.</Desc>
      </Error>
      <Link to="/">
        <Safty>Go Back</Safty>
      </Link>
    </Container>
  );
};

export default Enonet;

import styled from "styled-components";
import { devices } from "../../../assets/Data/Breakpoints";

export const FooterBanner = styled.footer`
  width: 100%;
  background-color: #00009A;
  // background-color: #232323;
  color: #fff;
  padding: 30px 30px 0 30px;

  @media ${devices.tablet} {
    padding: 30px 15px 0 15px;
  }
  @media ${devices.mobileM} {
    padding: 15px 15px 0 15px;
  }
`;

export const Div = styled.div`
  display: flex;
  justify-content: space-around;
  border-top: 1px solid gray;
  padding: 10px 0;
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 1000px;
  width 100%;
  margin: 20px auto;
`;

export const LogoContainer = styled.div`
  width: 100%;
`;

export const Address = styled.address`
  font-style: italics;
  margin: 10px 0;

  @media ${devices.mobileL}{
    font-size: 0.8rem;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  font-size: 2rem;
  gap: 10px;

  @media ${devices.mobileM} {
    font-size: 1.3rem;
  }
`;

export const Nav = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  
  @media ${devices.tablet} {
    flex-direction: column;
    gap: 20px;
    margin: 0;
    align-items: center;
    justify-content: flex-start;
  }

  @media ${devices.mobileL} {
    align-items: flex-end;
    justify-content: flex-start;
  }
`;

export const IconLinks = styled.a`
  color: #fff;
`;

export const Policy = styled.p`
  @media ${devices.mobileM} {
    font-size: 0.9rem;
  }
`;

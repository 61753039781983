// our facilities photo 
import class1 from "../Images/Facilities/class1-main-thumbs.jpg";
import class2 from "../Images/Facilities/class2-main-thumbs.jpg";
import class3 from "../Images/Facilities/class3-main-thumbs.jpg";
import class4 from "../Images/Facilities/class4-main-thumbs.jpg";
import class5 from "../Images/Facilities/class5-main-thumbs.jpg";
import class6 from "../Images/Facilities/class6-main-thumbs.jpg";
import class7 from "../Images/Facilities/class7-main-thumbs.jpg";
import class11 from "../Images/Facilities/class1-main.jpg";
import class12 from "../Images/Facilities/class2-main.jpg";
import class13 from "../Images/Facilities/class3-main.jpg";
import class14 from "../Images/Facilities/class4-main.jpg";
import class15 from "../Images/Facilities/class5-main.jpg";
import class16 from "../Images/Facilities/class6-main.jpg";
import class17 from "../Images/Facilities/class7-main.jpg";
import serverRoom1 from "../Images/Facilities/serve-room3-main-thumbs.jpg";
import serverRoom2 from "../Images/Facilities/server-room-main-thumbs.jpg";
import serverRoom3 from "../Images/Facilities/server-room2-main-thumbs.jpg";
import serverRoom11 from "../Images/Facilities/serve-room3-main.jpg";
import serverRoom12 from "../Images/Facilities/server-room-main.jpg";
import serverRoom13 from "../Images/Facilities/server-room2-main.jpg";
import lobby1 from '../Images/Facilities/lobby1-main-thumbs.jpg'
import lobby2 from '../Images/Facilities/lobby2-main-thumbs.jpg'
import lobby3 from '../Images/Facilities/looby5-main-thumbs.jpg'
import lobby11 from '../Images/Facilities/lobby1-main.jpg'
import lobby12 from '../Images/Facilities/lobby2-main.jpg'
import lobby13 from '../Images/Facilities/looby5-main.jpg'
import student from '../Images/Facilities/classroom_1_Thumbs.jpg'
import student1 from '../Images/Facilities/classroom_6_Thumbs.jpg'
import student2 from '../Images/Facilities/classroom_4_Thumbs.jpg'
import student3 from '../Images/Facilities/classroom_9_Thumbs.jpg'




// Anambra Imo Basin photos 
import group1 from '../Images/gallary/Anambra-imo-basin/banner-thumbs.jpg';
import group2 from '../Images/gallary/Anambra-imo-basin/group1-thumbs.jpg';
import group3 from '../Images/gallary/Anambra-imo-basin/group2-thumbs.jpg';
import group4 from '../Images/gallary/Anambra-imo-basin/group3-thumbs.jpg';
import group5 from '../Images/gallary/Anambra-imo-basin/group4-thumbs.jpg';
import group6 from '../Images/gallary/Anambra-imo-basin/group5-thumbs.jpg';
import group7 from '../Images/gallary/Anambra-imo-basin/group6-thumbs.jpg';
import group8 from '../Images/gallary/Anambra-imo-basin/group7-thumbs.jpg';
import group9 from '../Images/gallary/Anambra-imo-basin/group8-thumbs.jpg';
import group10 from '../Images/gallary/Anambra-imo-basin/group9-thumbs.jpg';
import group11 from '../Images/gallary/Anambra-imo-basin/banner.jpg';
import group12 from '../Images/gallary/Anambra-imo-basin/group1.jpg';
import group13 from '../Images/gallary/Anambra-imo-basin/group2.jpg';
import group14 from '../Images/gallary/Anambra-imo-basin/group3.jpg';
import group15 from '../Images/gallary/Anambra-imo-basin/group4.jpg';
import group16 from '../Images/gallary/Anambra-imo-basin/group5.jpg';
import group17 from '../Images/gallary/Anambra-imo-basin/group6.jpg';
import group18 from '../Images/gallary/Anambra-imo-basin/group7.jpg';
import group19 from '../Images/gallary/Anambra-imo-basin/group8.jpg';
import group110 from '../Images/gallary/Anambra-imo-basin/group9.jpg';
import inclass1 from '../Images/gallary/Anambra-imo-basin/imo_basin_2 Thumbs.jpg';
import inclass2 from '../Images/gallary/Anambra-imo-basin/imo_basin Thumbs.jpg';
import inclass3 from '../Images/gallary/Anambra-imo-basin/imo_basin_3 Thumbs.jpg';



// Roseville academy photos 
import roseville1 from '../Images/gallary/Roseville-secondary-school/roseville1-thumbs.jpg'
import roseville2 from '../Images/gallary/Roseville-secondary-school/roseville2-thumbs.jpg'
import roseville3 from '../Images/gallary/Roseville-secondary-school/roseville3-thumbs.jpg'
import roseville4 from '../Images/gallary/Roseville-secondary-school/roseville4-thumbs.jpg'
import roseville5 from '../Images/gallary/Roseville-secondary-school/roseville5-thumbs.jpg'
import roseville6 from '../Images/gallary/Roseville-secondary-school/roseville6-thumbs.jpg'
import roseville7 from '../Images/gallary/Roseville-secondary-school/roseville7-thumbs.jpg'
import roseville8 from '../Images/gallary/Roseville-secondary-school/roseville8-thumbs.jpg'
import roseville9 from '../Images/gallary/Roseville-secondary-school/roseville9-thumbs.jpg'
import roseville11 from '../Images/gallary/Roseville-secondary-school/roseville1.jpg'
import roseville12 from '../Images/gallary/Roseville-secondary-school/roseville2.jpg'
import roseville13 from '../Images/gallary/Roseville-secondary-school/roseville3.jpg'
import roseville14 from '../Images/gallary/Roseville-secondary-school/roseville4.jpg'
import roseville15 from '../Images/gallary/Roseville-secondary-school/roseville5.jpg'
import roseville16 from '../Images/gallary/Roseville-secondary-school/roseville6.jpg'
import roseville17 from '../Images/gallary/Roseville-secondary-school/roseville7.jpg'
import roseville18 from '../Images/gallary/Roseville-secondary-school/roseville8.jpg'
import roseville19 from '../Images/gallary/Roseville-secondary-school/roseville9.jpg'


// NYSC CDS sensitization photos 
import nysc1 from'../Images/gallary/NYSC/nysc1.jpg';
import nysc2 from'../Images/gallary/NYSC/nysc2.jpg';
import nysc3 from'../Images/gallary/NYSC/nysc3.jpg';


// FRSC Training photo 
import frsc1 from'../Images/gallary/FRSC/frsc1.jpg';
import frsc2 from'../Images/gallary/FRSC/frsc2.jpg';
import frsc3 from'../Images/gallary/FRSC/frsc3.jpg';
import frsc4 from'../Images/gallary/FRSC/frsc4.jpg';
import frsc5 from'../Images/gallary/FRSC/frsc5.jpg';
import frsc6 from'../Images/gallary/FRSC/frsc6.jpg';
import frsc7 from'../Images/gallary/FRSC/frsc7.jpg';
import frsc8 from'../Images/gallary/FRSC/frsc8.jpg';


// SS peter and paul photos 
import ssthumbs1 from '../Images/gallary/SSPeter/sSPeter1-thumbs.jpg';
import ssthumbs2 from '../Images/gallary/SSPeter/sSPeter2-thumbs.jpg';
import ssthumbs3 from '../Images/gallary/SSPeter/sSPeter3-thumbs.jpg';
import ssthumbs4 from '../Images/gallary/SSPeter/sSPeter4-thumbs.jpg';
import ssthumbs5 from '../Images/gallary/SSPeter/sSPeter5-thumbs.jpg';
import ssthumbs6 from '../Images/gallary/SSPeter/sSPeter6-thumbs.jpg';
import ssthumbs7 from '../Images/gallary/SSPeter/sSPeter7-thumbs.jpg';
import ssthumbs9 from '../Images/gallary/SSPeter/sSPeter9-thumbs.jpg';
import ssthumbs10 from '../Images/gallary/SSPeter/sSPeter10-thumbs.jpg';
import ssthumbs11 from '../Images/gallary/SSPeter/sSPeter11-thumbs.jpg';
import ssthumbs12 from '../Images/gallary/SSPeter/sSPeter12-thumbs.jpg';
import ssthumbs13 from '../Images/gallary/SSPeter/sSPeter13-thumbs.jpg';
import ssthumbs14 from '../Images/gallary/SSPeter/sSPeter14-thumbs.jpg';
import ssthumbs15 from '../Images/gallary/SSPeter/Sspeter_1 Thumbs.jpg';


import ss1 from '../Images/gallary/SSPeter/sSPeter1.jpg';
import ss2 from '../Images/gallary/SSPeter/sSPeter2.jpg';
import ss3 from '../Images/gallary/SSPeter/sSPeter3.jpg';
import ss4 from '../Images/gallary/SSPeter/sSPeter4.jpg';
import ss5 from '../Images/gallary/SSPeter/sSPeter5.jpg';
import ss6 from '../Images/gallary/SSPeter/sSPeter6.jpg';
import ss7 from '../Images/gallary/SSPeter/sSPeter7.jpg';
import ss9 from '../Images/gallary/SSPeter/sSPeter9.jpg';
import ss10 from '../Images/gallary/SSPeter/sSPeter10.jpg';
import ss11 from '../Images/gallary/SSPeter/sSPeter11.jpg';
import ss12 from '../Images/gallary/SSPeter/sSPeter12.jpg';
import ss13 from '../Images/gallary/SSPeter/sSPeter13.jpg';
import ss14 from '../Images/gallary/SSPeter/sSPeter14.jpg';

// our facilities photos 
export const OurOffice = [
  { img: student, thumbnail: student, alt: "classroom Photo1" },
  { img: class11, thumbnail: class1, alt: "classroom Photo1" },
  { img: student1, thumbnail: student1, alt: "classroom Photo1" },
  { img: lobby11, thumbnail: lobby1, alt: "lobby Photo1" },
  { img: class12, thumbnail: class2, alt: "classroom Photo2" },
  { img: student2, thumbnail: student2, alt: "classroom Photo2" },
  { img: serverRoom11, thumbnail: serverRoom1, alt: "server room Photo1" },
  { img: class13, thumbnail: class3, alt: "classroom Photo3" },
  { img: lobby12, thumbnail: lobby2, alt: "lobby Photo2" },
  { img: class14, thumbnail: class4, alt: "classroom Photo4" },
  { img: serverRoom12, thumbnail: serverRoom2, alt: "server room Photo2" },
  { img: student3, thumbnail: student3, alt: "server room Photo2" },
  { img: class15, thumbnail: class5, alt: "classroom Photo5" },
  { img: lobby13, thumbnail: lobby3, alt: "lobby Photo3" },
  { img: class16, thumbnail: class6, alt: "classroom Photo6" },
  { img: serverRoom13, thumbnail: serverRoom3, alt: "server room Photo3" },
  { img: class17, thumbnail: class7, alt: "classroom Photo7" },
];


// anambra imo basin data 
export const AIBasin = [
  {
    img: group110,
    thumbnail: group10,
    alt: "Anambra imo basin training photo",
  },
  {
    img: inclass1,
    thumbnail: inclass1,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: group11,
    thumbnail: group1,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: group12,
    thumbnail: group2,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: inclass2,
    thumbnail: inclass2,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: group13,
    thumbnail: group3,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: inclass3,
    thumbnail: inclass3,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: group14,
    thumbnail: group4,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: group15,
    thumbnail: group5,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: group16,
    thumbnail: group6,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: group17,
    thumbnail: group7,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: group18,
    thumbnail: group8,
    alt: "Anambra imo basin training group photo",
  },
  {
    img: group19,
    thumbnail: group9,
    alt: "Anambra imo basin training group photo",
  },
];


//Roseville Academy photo
export const Excursion = [
  { img: roseville11, thumbnail: roseville1, alt: "roseville excursion photo" },
  { img: roseville12, thumbnail: roseville2, alt: "roseville excursion photo" },
  { img: roseville13, thumbnail: roseville3, alt: "roseville excursion photo" },
  { img: roseville14, thumbnail: roseville4, alt: "roseville excursion photo" },
  { img: roseville15, thumbnail: roseville5, alt: "roseville excursion photo" },
  { img: roseville16, thumbnail: roseville6, alt: "roseville excursion photo" },
  { img: roseville17, thumbnail: roseville7, alt: "roseville excursion photo" },
  { img: roseville18, thumbnail: roseville8, alt: "roseville excursion photo" },
  { img: roseville19, thumbnail: roseville9, alt: "roseville excursion photo" },
];


// NYSC Sensitization programme data 
export const NyscCds = [
  { img: nysc1, thumbnail: nysc1, alt: "NYSC sensitization photo" },
  { img: nysc2, thumbnail: nysc2, alt: "NYSC sensitization photo" },
  { img: nysc3, thumbnail: nysc3, alt: "NYSC sensitization photo" },
];


// FRSC Training data
export const FrscTraining = [
  { img: frsc1, thumbnail: frsc1, alt: "FRSC Training photo" },
  { img: frsc2, thumbnail: frsc2, alt: "FRSC Training photo" },
  { img: frsc3, thumbnail: frsc3, alt: "FRSC Training photo" },
  { img: frsc4, thumbnail: frsc4, alt: "FRSC Training photo" },
  { img: frsc5, thumbnail: frsc5, alt: "FRSC Training photo" },
  { img: frsc6, thumbnail: frsc6, alt: "FRSC Training photo" },
  { img: frsc7, thumbnail: frsc7, alt: "FRSC Training photo" },
  { img: frsc8, thumbnail: frsc8, alt: "FRSC Training photo" },
];

  //SSpeater and paul photo
  export const SSpeter = [
    { img: ss1, thumbnail: ssthumbs1, alt: "SS Peter and Paul Training photo" },
    { img: ss2, thumbnail: ssthumbs2, alt: "SS Peter and Paul Training photo" },
    { img: ss3, thumbnail: ssthumbs3, alt: "SS Peter and Paul Training photo" },
    { img: ss4, thumbnail: ssthumbs4, alt: "SS Peter and Paul Training photo" },
    { img: ss5, thumbnail: ssthumbs5, alt: "SS Peter and Paul Training photo" },
    { img: ssthumbs15, thumbnail: ssthumbs15, alt: "SS Peter and Paul Training photo" },
    { img: ss6, thumbnail: ssthumbs6, alt: "SS Peter and Paul Training photo" },
    { img: ss7, thumbnail: ssthumbs7, alt: "SS Peter and Paul Training photo" },
    { img: ss9, thumbnail: ssthumbs9, alt: "SS Peter and Paul Training photo" },
    { img: ss10, thumbnail: ssthumbs10, alt: "SS Peter and Paul Training photo" },
    { img: ss11, thumbnail: ssthumbs11, alt: "SS Peter and Paul Training photo" },
    { img: ss12, thumbnail: ssthumbs12, alt: "SS Peter and Paul Training photo" },
    { img: ss13, thumbnail: ssthumbs13, alt: "SS Peter and Paul Training photo" },
    { img: ss14, thumbnail: ssthumbs14, alt: "SS Peter and Paul Training photo" },
  ]
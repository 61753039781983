import {
  Figure,
  H2,
  Hr,
  Image,
  ImgContainer,
  ListItem,
  Para,
  Ul,
  WhyusSection,
} from './WhyUs.styles';
import impact from '../../assets/Impacts/impact.jpg';
import impact2 from '../../assets/Impacts/impact2.jpg';
import impact3 from '../../assets/Impacts/impact3.jpg';
import impact4 from '../../assets/Impacts/impact4.jpg';
import { Fade } from 'react-awesome-reveal';

const WhyUs = () => {
  return (
    <WhyusSection className="why-us" id="whyus">
      <H2>Why Choose Us?</H2>
      <Hr />
      <Para>
        IGNA ICT Consulting Limited is a licencee of NIIT Limited and NIIT
        Franchise Partner an international organization with
      </Para>
      <ImgContainer className="why-us-img-container">
        <Fade bottom>
          <Figure>
            <Image src={impact} alt="NIIT impacct world wide" />
          </Figure>
        </Fade>
        <Fade bottom>
          <Figure>
            <Image src={impact2} alt="NIIT impacct world wide" />
          </Figure>
        </Fade>
        <Fade bottom>
          <Figure>
            <Image src={impact3} alt="NIIT impacct world wide" />
          </Figure>
        </Fade>
        <Fade bottom>
          <Figure>
            <Image src={impact4} alt="NIIT impacct world wide" />
          </Figure>
        </Fade>
      </ImgContainer>
      <Para>
        Aside from this, Let us share with you the remarkable features that set
        us apart and make us the ideal choice for your tech education needs.
      </Para>
      <Ul>
        <Fade bottom>
          <ListItem>
            Community-Oriented Approach: At IGNA, we believe in the power of a
            strong community. We foster a supportive and inclusive environment
            where students collaborate, network, and grow together. Our diverse
            community comprises aspiring tech enthusiasts, experienced
            professionals, and industry experts who share a common passion for
            technology. You'll have ample opportunities to connect with
            like-minded individuals, form valuable partnerships, and build
            lifelong friendships within our thriving tech community.
          </ListItem>
        </Fade>
        <Fade bottom>
          <ListItem>
            Exceptional Teaching Professionals: Our greatest strength lies in
            our exceptional team of teaching professionals. We have carefully
            handpicked accomplished experts from the tech industry who are not
            only highly skilled but also passionate about sharing their
            knowledge. Our instructors bring real-world experience and
            cutting-edge insights to the classroom, ensuring that you receive
            top-notch education that is both relevant and up-to-date. With their
            guidance, you'll be learning from the very best, gaining invaluable
            industry perspectives, and staying ahead of the curve.
          </ListItem>
        </Fade>
        <Fade bottom>
          <ListItem>
            Modern Techniques and Tools: Technology is ever-evolving, and so are
            we. At IGNA, we pride ourselves on our commitment to staying at the
            forefront of the tech landscape. Our curriculum is meticulously
            crafted to incorporate the latest advancements, ensuring that you
            acquire the most sought-after skills in the industry. From
            programming languages to data analysis, cybersecurity to artificial
            intelligence, our courses are designed to equip you with the
            knowledge and practical expertise needed to excel in today's digital
            age. We provide access to state-of-the-art facilities and
            cutting-edge tools, allowing you to explore, experiment, and apply
            your learning in a real-world context.
          </ListItem>
        </Fade>
        <Fade bottom>
          <ListItem>
            Lifelong Learning and Continuous Support: Your journey with IGNA
            doesn't end with graduation. We are committed to your long-term
            success and offer continuous support even after you complete your
            program. Whether it's through our alumni network, career services,
            or ongoing professional development opportunities, we are dedicated
            to helping you thrive in your tech career. We believe in lifelong
            learning and provide access to resources, events, and workshops that
            allow you to stay updated with the latest trends and further enhance
            your skills.
          </ListItem>
        </Fade>
        {/* <Fade bottom>
          <ListItem>
            NIIT Continues to pioneer in providing latest technology digital
            transformation training program like Power Business Intelligence
            (BI), Python, Internet of Thing (IoT), Block Chain, Artificial
            Intelligence (AI), Machine Learning with R, Data Analytics,
            diginxt-MMS, BIG DATA, Java with DevOps, Digital Marketing.
          </ListItem>
        </Fade>
        <Fade bottom>
          <ListItem>
            NIIT USA has been ranked among the Top 20 Training Outsourcing
            Companies 2017 by TrainingIndustry.com for the 10th consecutive
            year.
          </ListItem>
        </Fade>
        <Fade bottom>
          <ListItem>
            NIIT UK has been awarded the status of 'Accredited Learning
            Provider' 2017 by Learning and Performance Institute (LPI), UK for
            the Second Consecutive Year.
          </ListItem>
        </Fade> */}
      </Ul>
    </WhyusSection>
  );
};

export default WhyUs;

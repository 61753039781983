import styled from "styled-components";
import { devices } from "../../assets/Data/Breakpoints";

export const WhyusSection = styled.section`
  width: 95%;
  max-width: 800px;
  margin: 50px auto;
  padding: 10px;
`;

export const Hr = styled.hr`
  width: 50px;
  height: 10px;
  background-color: rgb(61, 54, 186);
  border: none;
  border-radius: 10px;
  margin: 10px 0;
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Figure = styled.figure`
  @media (max-width: 500px) {
    max-width: 150px;
    width: 100%;
  }

  @media ${devices.mobileM} {
    max-width: 124px;
  }

  @media ${devices.mobileS} {
    max-width: 100px;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const H2 = styled.h2`
  padding: 2px 0;

  @media ${devices.mobileL} {
    font-size: 1.3rem;
  }
`;

export const Para = styled.p`
  margin: 10px 0;
`;

export const Ul = styled.ul`
  padding: 10px 0;
`;

export const ListItem = styled.li`
  padding: 10px 0;
  text-align: justify;

  @media ${devices.mobileL} {
    font-size: 0.9rem;
  }
`;

import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./routes/Index/Home";
import Courses from "./routes/Courses/Courses";
import About from "./routes/About/About";
import Contact from "./routes/Contact/Contact";
import Navigation from "./routes/Navigations/Navigation";
import Gallary from "./routes/Gallary/Gallary.component";
import CourseContainer from "./routes/CourseContainer/CourseContainer";
import CourseList from "./container/CourseList/CourseList.component";
import Enonet from "./routes/404/Enonet.component";

function App() {
  const [path, setPath] = useState("home");

  useEffect(() => {
    function changeTitle(page) {
      switch (page) {
        case "courses":
          document.title = "Courses | NIIT Enugu";
          break;
        case "about":
          document.title = "About | NIIT Enugu";
          break;
        case "contact":
          document.title = "Contact | NIIT Enugu";
          break;
        case "gallery":
          document.title = "Gallery | NIIT Enugu";
          break;
        default:
          document.title = "Home | NIIT Enugu";
          break;
      }
    }
    changeTitle(path);
  }, [path]);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigation path={setPath} />}>
          <Route index element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/category" element={<CourseContainer />}>
            <Route path=":category_id" element={<CourseList />} />
          </Route>
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallary />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Enonet />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import styled from "styled-components";
import img from "../../assets/Images/backgrounds/course_category.jpg";

export const CoursesContainer = styled.section`
  width: 100%;
  padding: 0 10px;
`;

export const Header = styled.header`
  padding: 30px 0;
  width: 100%;
  text-align: center;
  background: #799f0c;
  background: -webkit-linear-gradient(to right, #acbb7888, #799f0c88),
    url(${img});
  background: linear-gradient(to right, #acbb7888, #799f0c88), url(${img});
  background-position: center;
`;

export const HeaderTitle = styled.h2`
  font-size: 2rem;
`;

export const HeaderDetails = styled.p`
  max-width: 800px;
  margin: 15px auto;
  width: 100%;
  font-size: 1.1rem;
`;

export const CategoryContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  margin: 30px 0;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

export const Input = styled.input`
    padding: 10px 5px;
    border: none;
    outline: none;
    width: 100%;
    font-size: 1.1rem;
;`

export const Search = styled.fieldset`
  max-width: 700px;
  display: flex;
  align-items: center;
  width: 90%;
  padding: 2px 10px;
  border-radius: 10px;
`;

// Photo by <a href="https://unsplash.com/@climatereality?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">The Climate Reality Project</a> on <a href="https://unsplash.com/s/photos/courses?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>

import styled from "styled-components";
import { devices } from "../../assets/Data/Breakpoints";
import img from "../../assets/Images/backgrounds/testimonial.jpg";

export const TestimonialsContainer = styled.section`
  text-align: center;
  padding: 30px 20px 50px 20px;
  background: linear-gradient(rgba(72, 0, 72, 0.8), rgba(192, 72, 72, 0.8)),
    url(${img});
  background: -webkit-linear-gradient(
      rgba(72, 0, 72, 0.8),
      rgba(192, 72, 72, 0.8)
    ),
    url(${img});
  background: -moz-linear-gradient(rgba(72, 0, 72, 0.8), rgba(192, 72, 72, 0.8)),
    url(${img});
  background-position: center;
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 1020px;
  min-width: 280px;
  width: 100%;
  margin: 0 auto;
  justify-content: flex-start;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const H2 = styled.h2`
  color: white;
`;

export const Para = styled.p`
  color: white;

  @media ${devices.mobileL}{
    font-size: 0.9rem;
  }
`;

// Photo by <a href="https://unsplash.com/@headwayio?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Headway</a> on <a href="https://unsplash.com/s/photos/people-with-laptop?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>

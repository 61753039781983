import styled from 'styled-components';
import { devices } from '../../assets/Data/Breakpoints';

export const Img = styled.img`
  max-width: 300px;
  height: 250px;
  display: block;
  border-radius: 4px;
`;

export const LargeImg = styled.img`
  max-width: 800px;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
  
  @media ${devices.mobileL} {
    width: 100%;
    max-height: 70vh;
  }
`;
import React, { useState } from 'react';
import {
  Hamburger,
  HeaderBanner,
  Li,
  Logo,
  Partition,
  Ul,
} from './Header.styles';
import { Link } from 'react-router-dom';
import niitLogo from '../../../assets/niit_logo.png';
import { RiMenu4Fill } from 'react-icons/ri';
import { MdClose } from 'react-icons/md';
import Sidemenu from '../../../component/Sidemenu/Sidemenu.component';

const Header = ({ path }) => {
  const [menu, setMenu] = useState(false);
  const openMenu = () => {
    setMenu(!menu);
  };
  return (
    <HeaderBanner>
      <Partition>
        <Link to="/">
          <Logo src={niitLogo} alt="Niit logo" />
        </Link>
      </Partition>
      <Partition $mode="right">
        <Hamburger>
          {menu ? (
            <MdClose onClick={openMenu} />
          ) : (
            <RiMenu4Fill onClick={openMenu} />
          )}
        </Hamburger>
        <Sidemenu display={menu} path={path} closeBar={openMenu} />
        <Ul>
          <Link
            to="/"
            style={{ textDecoration: 'none' }}
            onClick={() => path('home')}
          >
            <Li>Home</Li>
          </Link>
          <Link
            to="/courses"
            style={{ textDecoration: 'none' }}
            onClick={() => path('courses')}
          >
            <Li>Courses</Li>
          </Link>
          <Link
            to="/gallery"
            style={{ textDecoration: 'none' }}
            onClick={() => path('gallery')}
          >
            <Li>Gallery</Li>
          </Link>
          <Link
            to="/about"
            style={{ textDecoration: 'none' }}
            onClick={() => path('about')}
          >
            <Li>About</Li>
          </Link>
          <Link
            to="/contact"
            style={{ textDecoration: 'none' }}
            onClick={() => path('contact')}
          >
            <Li>Contact</Li>
          </Link>
        </Ul>
      </Partition>
    </HeaderBanner>
  );
};

export default Header;

import React from "react";
import {
  CategoryContainer,
  CoursesContainer,
  Header,
  HeaderDetails,
  HeaderTitle,
} from "./Courses.styles";
// import { MdOutlineSearch } from "react-icons/md";
import CourseCategory from "../../component/CourseCategory/CourseCategory.component";
import { categories } from "../../assets/Data/Data";
import { Iframe, IframeContainer } from "../../container/CommonStyles";

function Courses() {
  return (
    <CoursesContainer>
      <Header>
        <HeaderTitle>Courses</HeaderTitle>
        <HeaderDetails>
          If you have ambition, IGNA ICT Consulting has a course to match.
        </HeaderDetails>
      </Header>
      {/* <Form action="">
        <Search>
          <MdOutlineSearch style={{ fontSize: "1.6rem" }} />
          <Input type="search" name="" id="" placeholder="Search courses..." />
        </Search>
      </Form> */}
      <IframeContainer>
        <Iframe
          src="https://www.youtube.com/embed/NV5id6zMTRI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></Iframe>
      </IframeContainer>
      <CategoryContainer>
        {categories.map((item, i) =>
          item.status === "image" ? (
            <figure style={{ width: "100%" }}>
              <img style={{ width: "100%" }} src={item.src} alt="courses" />
            </figure>
          ) : (
            <CourseCategory
              key={i}
              title={item.title}
              summary={item.summary}
              route={item.route}
              course={item.courses}
            />
          )
        )}
      </CategoryContainer>
    </CoursesContainer>
  );
}

export default Courses;

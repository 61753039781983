import React from 'react';
import { Hr, Img } from '../CommonStyles';
import { Div, Figure, PartnersContainer } from './Partner.styles';
import partner1 from '../../assets/partners/Anambra-Imo-River-Basin-Development-Authority.png';
import partner2 from '../../assets/partners/IGNA-ICT.png';
import partner3 from '../../assets/partners/NIIT.png';
import partner4 from '../../assets/partners/ROSEVILLE.webp';
import partner5 from '../../assets/partners/Heradics.png';
import { Slide } from 'react-awesome-reveal';

const Partner = () => {
  return (
    <PartnersContainer>
      <h2>Our Partners</h2>
      <Hr partner />
      <Slide bottom>
        <Div>
          <Figure>
            <Img src={partner1} alt="anambra imo river basin logo" />
          </Figure>
          <Figure>
            <Img src={partner2} alt="niit imperia logo" />
          </Figure>
          <Figure>
            <Img src={partner3} alt="niit cloud campus logo" />
          </Figure>
          <Figure>
            <Img src={partner4} alt="ifbi logo" />
          </Figure>
          <Figure>
            <Img src={partner5} alt="niit YuvaJyoti logo" />
          </Figure>
        </Div>
      </Slide>
    </PartnersContainer>
  );
};

export default Partner;

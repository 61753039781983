import styled from "styled-components";
import { devices } from "../../assets/Data/Breakpoints";

export const PartnersContainer = styled.section`
  text-align: center;
  padding: 50px 10px;
`;

export const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media ${devices.mobileL}{
    flex-wrap: wrap;
  }
`;

export const Figure = styled.figure`
  max-width: 100px;
  min-width: 50px;
  width: 100%;
`;

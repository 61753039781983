import styled from "styled-components";

export const CourseListContainer = styled.section`
  margin-top: 20px;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  gap: 20px;
  border-bottom: 1px solid #cccccc;
  padding: 10px 0;
  max-width: 1020px;
  width: 100%;
  margin: 0 auto;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Li = styled.h4`
  color: #0000ff;
  overflow:hidden;
`;

import React, { useState, useRef } from "react";
import { Link, Outlet } from "react-router-dom";
import { CourseListContainer, Li, Nav } from "./CourseContainer.styles";

const CourseContainer = () => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const slider = useRef();
  return (
    <CourseListContainer>
      <Nav
        ref={slider}
        onMouseDown={(e) => {
          setIsDown(true);
          setStartX(e.pageX - slider.current.offsetLeft);
          setScrollLeft(slider.current.scrollLeft);
        }}
        onMouseLeave={(e) => setIsDown(false)}
        onMouseUp={(e) => setIsDown(false)}
        onMouseMove={(e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.current.offsetLeft;
          const walk = (x - startX) * 2;
          slider.current.scrollLeft = scrollLeft - walk;
        }}
      >
        <Link to="/category/All">
          <Li>All</Li>
        </Link>{" "}
        |
        <Link to="/category/Master_Mind_Series">
          <Li>Master Mind Series</Li>
        </Link>{" "}
        |
        <Link to="/category/DigiNxt_Series">
          <Li>DigiNxt Series</Li>
        </Link>{" "}
        |
        <Link to="/category/Career_Enhancement_Programme">
          <Li>Career Enhancement Programme</Li>
        </Link>{" "}
        |
        <Link to="/category/Employability_Diploma_Programme">
          <Li>Employability Diploma Programme</Li>
        </Link>
      </Nav>
      <Outlet />
    </CourseListContainer>
  );
};

export default CourseContainer;

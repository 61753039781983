const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
};

export const devices = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
};




// Photo by <a href="https://unsplash.com/@elishavision?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Elizeu Dias</a> on <a href="https://unsplash.com/s/photos/black-faces?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/@eyeforebony?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Eye for Ebony</a> on <a href="https://unsplash.com/s/photos/black-faces?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/@eyeforebony?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Eye for Ebony</a> on <a href="https://unsplash.com/s/photos/black-faces?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/es/@joaccord?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Joshua Oluwagbemiga</a> on <a href="https://unsplash.com/s/photos/black-faces?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/es/@jasonclicks?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Mesbapi Mari</a> on <a href="https://unsplash.com/s/photos/black-faces?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>

// remove
// Photo by <a href="https://unsplash.com/@adolfofelix?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Adolfo Félix</a> on <a href="https://unsplash.com/s/photos/offices?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/@sunday_digital?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Nastuh Abootalebi</a> on <a href="https://unsplash.com/s/photos/offices?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/ja/@reddalec?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Redd</a> on <a href="https://unsplash.com/s/photos/offices?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/@jasongoodman_youxventures?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jason Goodman</a> on <a href="https://unsplash.com/s/photos/offices?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/@marvelous?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Marvin Meyer</a> on <a href="https://unsplash.com/s/photos/offices?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/@topeasokere?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Tope. A Asokere</a> on <a href="https://unsplash.com/s/photos/offices-building-lagos?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// Photo by <a href="https://unsplash.com/@disruptxn?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Desola Lanre-Ologun</a> on <a href="https://unsplash.com/s/photos/black-student?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
  
import React, { useRef, useState } from 'react';
import { GiDiamondTrophy } from 'react-icons/gi';
import { MdVerified } from 'react-icons/md';
import { FaGraduationCap } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import {
  AboutContainer,
  Figure,
  Gallary,
  Growth,
  GrowthGrid,
  Header,
  Heading,
  Heading4,
  IconWrap,
  Niche,
  NicheContainer,
  NichePara,
  OurStory,
  OurValues,
  Para,
  Span,
  Value,
  ValueContainer,
} from './About.styles';
import Partner from '../../container/Partner/Partner.component';

const About = () => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const slider = useRef();
  return (
    <AboutContainer>
      <Header>
        <NicheContainer>
          <Niche>
            We <Span $mode="niche">pass</Span> the right information to the
            right people <br />
            <Span $mode="niche">
              in the right format with the right course.
            </Span>
          </Niche>
          <NichePara>
            In a time of chasing the latest trend,{' '}
            <Span $mode="catchphrase">create your own.</Span>
          </NichePara>
        </NicheContainer>
        <Gallary
          ref={slider}
          onMouseDown={(e) => {
            setIsDown(true);
            setStartX(e.pageX - slider.current.offsetLeft);
            setScrollLeft(slider.current.scrollLeft);
          }}
          onMouseLeave={(e) => setIsDown(false)}
          onMouseUp={(e) => setIsDown(false)}
          onMouseMove={(e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.current.offsetLeft;
            const walk = (x - startX) * 2;
            slider.current.scrollLeft = scrollLeft - walk;
          }}
        >
          <Figure $mode="slide1" />
          <Figure $mode="slide2" />
          <Figure $mode="slide3" />
          <Figure $mode="slide4" />
          <Figure $mode="slide5" />
          <Figure $mode="slide6" />
          <Figure $mode="slide7" />
        </Gallary>
      </Header>
      <OurStory>
        <Heading>Our Story</Heading>
        <Para $mode="default">
          IGNA ICT Consulting also know with NIIT Enugu is an affliate and
          licencee by NIIT brand worldwide which are a leading Skills and Talent
          Development Corporation that is building a manpower pool for global
          industry requirements.
        </Para>
        <Para $mode="default">
          IGNA ICT Consulting was Founded in 2018 to prepare people for industry
          4.0, IGNA ICT Consulting Enugu have impacted and graduated thousands
          of student in the latest and in demand skills in technology. We have
          engages in various community building exercises, training and
          sensitization.
        </Para>
      </OurStory>
      <OurValues>
        <Heading $mode="value">Our Values</Heading>
        <Para $mode="val-intro">
          To prepare students for a career in the creative, competitive and
          challenging world of computer programming.
        </Para>
        <ValueContainer>
          <Value>
            <IconWrap>
              <GiDiamondTrophy />
            </IconWrap>
            <Heading4>Quality</Heading4>
            <Para $mode="value">
              We ensure to provide quality courses that are designed to meet the
              needs of all learners. We offer courses in a variety of formats,
              including online and instructor-led training. All our instructors
              are certified professionals with years of experience in their
              fields. We also provide job assistance for clients who have
              completed our training programs and need help finding employment
              after graduation.
            </Para>
          </Value>
          <Value $mode="value1">
            <IconWrap>
              <MdVerified />
            </IconWrap>
            <Heading4>Trustworthy</Heading4>
            <Para $mode="value">
              We have a long history of providing high-quality products and
              excellent customer service. Our mission is to provide you with the
              best possible service, so that you can enjoy your new product for
              many years to come.
            </Para>
          </Value>
          <Value $mode="value2">
            <IconWrap>
              <FaGraduationCap />
            </IconWrap>
            <Heading4>Continous Learning</Heading4>
            <Para $mode="value">
              At IGNA we empower students to pursue careers in technology. We
              see a future where the most valuable and in-demand jobs are in
              technology. So we created a school to provide relevant tech
              education, focusing on the development of the skills that
              employers need.
            </Para>
          </Value>
          <Value $mode="value3">
            <IconWrap>
              <HiUserGroup />
            </IconWrap>
            <Heading4>Community</Heading4>
            <Para $mode="value">
              We have a community of developers, engineers and employers around
              you. With our events, networking opportunities and online
              community, we're here to help you get where you want to be in tech
            </Para>
          </Value>
          <Figure $mode="special" />
        </ValueContainer>
      </OurValues>
      <Growth>
        <div>
          <Heading $mode="growth">
            Ahead is Now,
            <br />
            Together We <Span $mode="green">Grow</Span>
          </Heading>
        </div>
        <GrowthGrid>
          <Para $mode="growth">
            <Span $mode="growth">Over 50+</Span> <br />
            modern digital courses
          </Para>
          <Para $mode="growth">
            <Span $mode="growth">Over 5000+</Span> <br />
            Tech expert trained
          </Para>
          <Para $mode="growth">
            <Span $mode="growth">Over 100+</Span> <br />
            daily office visits
          </Para>
          {/* <Para $mode="growth">
            <Span $mode="growth">Over 50+</Span> <br />
            campuses worldwide
          </Para> */}
          <Para $mode="growth">
            <Span $mode="growth">Over 3,00+</Span> <br />
            organization impacted
          </Para>
        </GrowthGrid>
      </Growth>
      <Partner />
    </AboutContainer>
  );
};

export default About;

import styled, { css } from "styled-components";

export const Form = styled.form`
  max-width: 600px;
  min-width: 300px;
  width: 95%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  max-height: 80vh;
  overflow-y: scroll;
  margin: 0 auto;

  ${(props) => {
    switch (props.$mode) {
      case "success":
        return css`
          display: none;
        `;
      case "error":
        return css`
          display: none;
        `;
      default:
        return css``;
    }
  }}
`;

export const Instruction = styled.div`
  width: 100%;
  text-align: center;
  margin: 30px 0;
`;

export const FormTitle = styled.h3`
  font-size: 1.4rem;
`;
export const FormDetail = styled.p`
  font-size: 1.05rem;
  color: #222222;
`;

export const Fieldset = styled.fieldset`
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Input = styled.input`
  outline: none;
  border: none;
  padding: 10px 5px;
  font-size: 1rem;
  width: 100%;
  border-radius: 4px;

  ${(props) => {
    switch (props.$mode) {
      case "submit":
        return css`
          background-color: #0000ff;
          color: #ffffff;
          cursor: pointer;
        `;
      default:
        return css`
          background-color: #cccccc;
        `;
    }
  }}
`;

export const Select = styled.select`
  outline: none;
  border: none;
  padding: 10px 5px;
  font-size: 1rem;
  width: 100%;
  background: #cccccc;
  border-radius: 4px;
  display: block;
`;

export const Textarea = styled.textarea`
  outline: none;
  border: none;
  padding: 10px 5px;
  font-size: 1rem;
  resize: none;
  //   width: 100%;
  background: #cccccc;
  border-radius: 4px;
`;

export const Label = styled.label`
  font-size: 1rem;
  width: 100%;

  &::after {
    content: "*";
    color: red;
  }
`;

export const FormSuccess = styled.div`
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  padding: 30px 20px;
  margin: 0 auto;
  text-align: center;
  display: none;

  ${(props) => {
    switch (props.$mode) {
      case "success":
        return css`
          display: block;
        `;
      case "error":
        return css`
          display: block;
        `;
      default:
        return css``;
    }
  }}
`;

export const FormSuccessHeading = styled.h3`
  color: green;
  font-size: 2rem;
`;

export const FormSuccessPara = styled.p`
  font-size: 1rem;
  margin: 20px auto;
`;

export const GoBack = styled.button`
  border: none;
  border-radius: 5px;
  padding: 10px 25px;
  background-color: blue;
  color: white;
`;

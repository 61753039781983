import img from "../Images/Niit-images/niit-img.jpg";
import img1 from "../Images/Niit-images/niit-img1.jpg";
import img2 from "../Images/Niit-images/niit-img2.jpg";
import img3 from "../Images/Niit-images/niit-img3.jpg";
import img4 from "../Images/Niit-images/niit-img4.jpg";
import img5 from "../Images/Niit-images/niit-img5.jpg";
import img6 from "../Images/Niit-images/niit-img6.jpg";
import img7 from "../Images/Niit-images/niit-img7.jpg";
import img8 from "../Images/Niit-images/niit-img8.jpg";
import img9 from "../Images/Niit-images/niit-img9.jpg";
import img10 from "../Images/Niit-images/niit-img10.jpg";
import img11 from "../Images/Niit-images/niit-img11.jpg";
import img12 from "../Images/Niit-images/niit-img12.jpg";
import img13 from "../Images/Niit-images/niit-img13.jpg";
import img14 from "../Images/Niit-images/niit-img14.jpg";
import img15 from "../Images/Niit-images/niit-img15.jpg";
import img16 from "../Images/Niit-images/niit-img16.jpg";
import img17 from "../Images/Niit-images/niit-img17.jpg";
import img18 from "../Images/Niit-images/niit-img18.jpg";
import img19 from "../Images/Niit-images/niit-img19.jpg";
import img20 from "../Images/gallary/Anambra-imo-basin/group3-thumbs.jpg";
import img21 from "../Images/Facilities/classroom_1_Thumbs.jpg";
import img22 from "../Images/Facilities/classroom_7_Thumbs.jpg";
import img23 from "../Images/gallary/SSPeter/sSPeter9-thumbs.jpg";

export const images = [
  img,
  img1,
  img2,
  img21,
  img3,
  img4,
  img5,
  img6,
  img22,
  img7,
  img8,
  img23,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
];
